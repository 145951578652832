import React, {useEffect, useState} from "react";
import "./../../styles/vouchers.css"

import { ToogleContext } from "../../hooks/ToogleContext";
import { VoucherDetailModal } from "./VoucherDetailModal";
import { AssignVoucherForm } from "../AdminPanel/AssignVoucherForm";
import { getGraphQl } from "../../hooks/ApiCalls";

import viewIcon from "./../../imgs/ActionsIcons/view.svg";
import confirmIcon from "./../../imgs/ActionsIcons/confirm.svg";
import cancelIcon from "./../../imgs/ActionsIcons/cancel.svg";


function Vouchers ({productName, isAssigned, voucherGuid, purchaseDate, enabled}) {

    const {userLocation, setuserLocation} = React.useContext(ToogleContext);
    const [openVoucherDetails, setopenVoucherDetails] = React.useState(false);
    const [shouldOpenActionMenu, setshouldOpenActionMenu] = React.useState(false);
    const [showAssingVoucher, setshowAssingVoucher] =useState(false);

    const handleVoucherActions = () => {
        setshouldOpenActionMenu(!shouldOpenActionMenu);
    };

    const toogleAssingButtomClick = () => {
        setshowAssingVoucher(!showAssingVoucher);
      };

    const handleCloseUserDetails = () => {
        setopenVoucherDetails(false);
    };

    const handleViewDetails = () => {
        setshouldOpenActionMenu(false);
        setopenVoucherDetails(true);
    };

    const handleUnAssigned =  async () => {
        let graphQuery = `
            mutation {
                unAssignVoucher(Vouchers:{voucherGuid:"${voucherGuid}"}) {
                message
                status
                }
            }
        `;
        const {data:{data:{unAssignVoucher:{status}}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});

        if(status==200){
            alert("Voucher Unassigned correctly");
            window.location.reload();
        }
    };

    useEffect(() => {
        setuserLocation([false,false,false,false,false,false,true,false]);
      }, []);

    return (
        <React.Fragment>
            <tr className="vouchers-table-line">
                <td className="vouchers-table-item">{purchaseDate}</td>
                <td className="vouchers-table-item">{productName}</td>
                <td className="vouchers-table-item">{isAssigned== true? <img  className="course-inscription-entry-action-icon"src ={confirmIcon}/>: <a className="vouchers-assign-btn" onClick={toogleAssingButtomClick}>assign</a>} </td>
                <td className="vouchers-table-item"><img  className="course-inscription-entry-action-icon"src ={enabled == true ? confirmIcon : cancelIcon}/></td>
                <td className="vouchers-table-actions-item" onClick={handleVoucherActions}>Actions</td>
                    
            </tr> 
            <div className= {`vouchers-action-menu ${shouldOpenActionMenu && "vouchers-action-menu-open" } `}>
                    <div className="vouchers-action-items-container">
                        <div className="vouchers-action-item-container">
                            <li className="vouchers-action-item" onClick={handleViewDetails}>View Details</li>
                            <img src={viewIcon} className="vouchers-action-icon"/>
                        </div>
                        {
                            isAssigned == true && enabled == true? 
                            <div className="vouchers-action-item-container">
                                <li className="vouchers-action-item" onClick={handleUnAssigned}>UnAssign</li>
                                <img src={viewIcon} className="vouchers-action-icon"/>
                            </div>
                            :
                            ""
                        }
                    </div>
            </div>

            {openVoucherDetails &&  <VoucherDetailModal voucherGuid= {voucherGuid} onCloseUserDetails={handleCloseUserDetails} />}
            {showAssingVoucher && <AssignVoucherForm voucherGuid= {voucherGuid} onCloseAssingVoucherFormClick={toogleAssingButtomClick}/>}
        </React.Fragment>

    );
}

export {Vouchers};