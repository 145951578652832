import React from "react";
import "./../styles/progressBar.css"

function ProgressBar ({progress}) {

    return (
        <div class="progress-bar" style={{"background-size":Number(progress)}}></div>
    );
}

export {ProgressBar};