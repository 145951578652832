import React from "react";
import logo from "./../../imgs/Brand/logo-main.webp";
import LoginPage from "../LoginPage";

import { useNavigate } from "react-router-dom";
import { LeftMenu } from "../../components/AdminPanel/LeftMenu";
import { StudentInscriptionView } from "../../components/AdminPanel/StudentInscriptionView";

import "./../../styles/dashboardPage.css";


import useToken from "../../hooks/useToken";
import { AddButtom } from "../../components/AdminPanel/AddButtom";
import { LogoutButtom } from "../../components/AdminPanel/LogoutButtom";

function StudentsInscriptions() {

  const {token, setToken} = useToken();
  const navigate = useNavigate();
  
  if (!token){
    return <LoginPage
    //  setToken={setToken}
    />
  }

  return (
    <React.Fragment>
     
      <div className="dashboardPage-main-container">
        
        <div className="dashboardPage-left-panel">
          <div className="dashboardPage-left-panel-header">
            <img src={logo} className="dashboardPage-logo" title="agile excellence main logo"
             alt="agilexcellence logo" onClick ={() => navigate("/")}/>
            <LeftMenu/>
          </div>
          <LogoutButtom/>
        </div>

        <div className="dashboardPage-right-panel">
          <AddButtom
            btn_name="Add student inscription"
          />
          <StudentInscriptionView/>
        </div>

      </div>
    </React.Fragment>
  );
}

export {StudentsInscriptions};