import React,{useEffect,useCallback} from "react";
import  {createPortal}  from "react-dom";
import "../../styles/AddNewProductForm.css";

import {getGraphQl} from "../../hooks/ApiCalls";

function AddNewLearningRouteForm ({onCloseAddNewLearningRouteFormClick}) {

    const [values, setValues] = React.useState({
        name:"",
        description: "",
        routeIdentifier: "",
        image: "",
      });

      const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
          onCloseAddNewLearningRouteFormClick();
        }
      }, []);
    
      useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
      }, [escFunction]);
    
      let graphQuery= `
          mutation {
            createLearningRoute(LearningRoute:{name:"${values.name}", description: "${values.description}", routeIdentifier: "${values.routeIdentifier}"}){
              message
            }
          }
        `;
    
      function handleChange(evt) {
        const { target } = evt;
        const { name, value } = target;
    
        const newValues = {
          ...values,
          [name]: value,
        };
        setValues(newValues);
      }
      
      const handleSubmit = async (evt) => {
        evt.preventDefault();
        const status = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
        if (status){window.alert("Route Created")}
        window.location.reload();
      }

    return createPortal(
          <div className="ModalBackground">
              <div className="add-product-form-main-container" onSubmit={handleSubmit}>
                  <div className="add-product-form-container">
                      <h1 className="add-product-form-title">Add new Route</h1>
                      <form className="add-product-form-inner-container">

                          <div className="add-product-form-items">
                              <input className="add-product-form-item" name= "name" type="text" placeholder="name" value={values.name} onChange={handleChange} required />
                              <input className="add-product-form-item" name= "description" type="text" placeholder="description" value={values.description} onChange={handleChange} required />
                              <input className="add-product-form-item" name= "routeIdentifier" type="text" placeholder="routeIdentifier" value={values.routeIdentifier} onChange={handleChange} required />
                              <input className="add-product-form-item" name= "image" type="text" placeholder="image" value={values.image} onChange={handleChange} required/>
                          </div>
                          
                          <div className="add-product-btns">
                              <button className="add-product-btn-create" type="submit"> Create Route </button>
                              <button className="add-product-btn-cancel" onClick={onCloseAddNewLearningRouteFormClick}> Cancel </button>
                          </div>
                      </form>
                  </div>
              </div>
            </div>
            ,
            document.getElementById("modal")
    );
}

export {AddNewLearningRouteForm};