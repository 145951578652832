import React, { useState, useEffect }  from "react";
import "./../../styles/toogleButtom.scss";

import {getGraphQl} from "./../../hooks/ApiCalls";

function ToogleButtom ({DBToogleValue, learningCourseId}) {

    const [inscriptionEnabledToogle, setinscriptionEnabledToogle] = React.useState(DBToogleValue);

    

    const toggleButtom = async () => {

        let graphQuery = `
            mutation{
                updateLearningCourses(LearningCourses:{learningCourseId: "${learningCourseId}", inscriptionEnabled: ${!inscriptionEnabledToogle}}) {
                id
                courseIdentifier
                name
                productId
                learningCourseId
                startDate
                endDate
                enabled
                inscriptionEnabled
                }
            }
        `;

        setinscriptionEnabledToogle(!inscriptionEnabledToogle);
        await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
        window.location.reload();
    };

    return (
        <label class="toggle-control">
            <input type="checkbox" checked= {inscriptionEnabledToogle ? "checked": ""}  onClick={toggleButtom}/>
            <span class="control"></span>
        </label>
    );
}

export {ToogleButtom};