import React from "react";

import { StudentsNavbar } from "../../components/Students/StudentsNavbar";
import { MyOpenInscriptionCoursesView } from "../../components/Students/MyOpenInscriptionCoursesView";

function StudentsHome () {

    return(
        <React.Fragment>
            <StudentsNavbar/>
            <MyOpenInscriptionCoursesView/>
        </React.Fragment>
    );
}

export { StudentsHome };