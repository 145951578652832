import React, {useState} from "react";
import logo from "./../../imgs/Brand/logo-main.webp";
import LoginPage from "../LoginPage";

import { useNavigate } from "react-router-dom";

import { LeftMenu } from "../../components/AdminPanel/LeftMenu";
import { ProductsView } from "../../components/AdminPanel/ProductsView";

import "./../../styles/dashboardPage.css";

import useToken from "../../hooks/useToken";
import { AddButtom } from "../../components/AdminPanel/AddButtom";
import { LogoutButtom } from "../../components/AdminPanel/LogoutButtom";
import { AddNewProductForm } from "../../components/AdminPanel/AddNewProductForm";

function ProductsList() {

  const [shouldShowProductDetailsModal, setshouldShowProductDetailsModal]= React.useState(false);
  const navigate = useNavigate();

  const toogleAddButtomClick = () => {
    setshouldShowProductDetailsModal(!shouldShowProductDetailsModal);
  };

  const {token, setToken} = useToken();
  
  if (!token){
    return <LoginPage
    //  setToken={setToken}
    />
  }

  

  return (
    <React.Fragment>
     
      <div className="dashboardPage-main-container">
        
        <div className="dashboardPage-left-panel">
          <div className="dashboardPage-left-panel-header">
            <img src={logo} className="dashboardPage-logo" title="agile excellence main logo" alt="agilexcellence logo" onClick ={() => navigate("/")}/>
            <LeftMenu/>
          </div>
          <LogoutButtom/>
        </div>

        <div className="dashboardPage-right-panel">
          <AddButtom
            btn_name="Add new product"
            onClick={toogleAddButtomClick}
          />
          <ProductsView/>
          {shouldShowProductDetailsModal && <AddNewProductForm onCloseAddNewProductFormClick={toogleAddButtomClick}/>}
        </div>

      </div>
    </React.Fragment>
  );
}

export {ProductsList};