import { useState } from "react";

export default function useTheme(){

    const getTheme = () => {
        const themeString = localStorage.getItem("theme");
        const userTheme = JSON.parse(themeString);
        return userTheme?.theme
    };

    const [theme, setTheme] = useState(getTheme());

    const saveTheme = userTheme => {
        localStorage.setItem('theme', JSON.stringify(userTheme));
        setTheme(userTheme.theme);
        window.location.reload(true);
    };

    return {
        setTheme: saveTheme, theme
    }

}