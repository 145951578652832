import React,{useEffect,useCallback} from "react";
import  {createPortal}  from "react-dom";
import "../../styles/AddCourseForm.css";

import {getGraphQl} from "./../../hooks/ApiCalls";

function AddCourseForm ({onCloseAddCourseFormClick}) {

    const [values, setValues] = React.useState({
        courseIdentifier: "",
        name:"",
        productId: "",
        startDate: "",
        endDate: "",
      });

      const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
          onCloseAddCourseFormClick();
        }
      }, []);
    
      useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
      }, [escFunction]);
    
      let graphQuery= `
            mutation{
                createLearningCourses(LearningCourses:{name:"${values.name}", productId: "${values.productId}", startDate: "${values.startDate}" ,endDate: "${values.endDate}",courseIdentifier:"${values.courseIdentifier}"}) {
                id
                }
            }
        `;
    
      function handleChange(evt) {
        const { target } = evt;
        const { name, value } = target;
    
        const newValues = {
          ...values,
          [name]: value,
        };
        setValues(newValues);
      }
      
      const handleSubmit = async (evt) => {
        // evt.preventDefault();
        await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery})
      }

    return createPortal(
          <div className="ModalBackground">
              <div className="add-course-form-main-container" onSubmit={handleSubmit}>
                  <div className="add-course-form-container">
                      <h1 className="add-course-form-title">Add new course Inscriptions</h1>
                      <form className="add-course-form-inner-container">

                          <div className="add-course-form-items">
                              <input className="add-course-form-item" name= "courseIdentifier" type="text" placeholder="courseIdentifier" value={values.courseIdentifier} onChange={handleChange} required />
                              <input className="add-course-form-item" name= "name" type="text" placeholder="name" value={values.name} onChange={handleChange} required />
                              <input className="add-course-form-item" name= "productId" type="text" placeholder="productId" value={values.productId} onChange={handleChange} required />
                              <input className="add-course-form-item" name= "startDate" type="date" placeholder="start Date" value={values.startDate} onChange={handleChange} required/>
                              <input className="add-course-form-item" name= "endDate" type="date" placeholder="end Date" value={values.endDate} onChange={handleChange} required/>
                          </div>
                          
                          <div className="add-course-btns">
                              <button className="add-course-btn-create" type="submit"> Create Course </button>
                              <button className="add-course-btn-cancel" onClick={onCloseAddCourseFormClick}> Cancel </button>
                          </div>
                      </form>
                  </div>
              </div>
            </div>
            ,
            document.getElementById("modal")
    );
}

export {AddCourseForm};