import React, {useEffect,useCallback,useState} from "react";
import  {createPortal}  from "react-dom";
import "./../../styles/userDetailModal.css";

import closeIcon from "./../../imgs/ActionsIcons/close-circle.svg";
import addBtn from "./../../imgs/ActionsIcons/money.svg";

import { ToogleContext } from "../../hooks/ToogleContext";
import { getGraphQl } from "../../hooks/ApiCalls";
import { isTheSameObject, getNewObject,buildMutationBody } from "../../utils/utils";
import { UserCoursesGrid } from "./UserCoursesGrid";

function VoucherDetailModal ({voucherGuid, onCloseUserDetails}) {

    const [inscriptionDetailModal, setInscriptionDetailModal] = React.useState("");
    const [inscriptionDetailInitialData,setinscriptionDetailInitialData] = React.useState("");
    const [isDetailsUpdated, setisDetailsUpdated] = React.useState(false);
    const [shouldShowAddPayment, setshouldShowAddPayment] =useState(false);

    function handleInscriptionDetailChange(evt) {
        const { target } = evt;
        const { name, value } = target;
    
        const newValues = {
          ...inscriptionDetailModal,
          [name]: value,
        };

        setInscriptionDetailModal(newValues);
        
        if (!isTheSameObject(newValues,inscriptionDetailInitialData)){
            setisDetailsUpdated(true);
        } else {
            setisDetailsUpdated(false);
        }

    }

    const handleSaveChanges = async (evt) => {
        
        evt.preventDefault();

        let auxObj = inscriptionDetailModal;
        delete auxObj.createdAt;

        let SaveChangesQuery = `
            mutation {
                updateVouchers(Vouchers:${buildMutationBody(auxObj)}) {
                message
                status
                }
            }
        `;
        
        let status = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: SaveChangesQuery});

        if (status){window.alert("Changes Saved")}
        window.location.reload();
    };

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            onCloseUserDetails();
        }
      }, []);
    
      useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
      }, [escFunction]);

    let graphQuery = `
        query{
            GetVoucherInfo(Vouchers:{voucherGuid:"${voucherGuid}"}) {
            productName
            productId
            value
            isAssigned
            voucherCost
            voucherVendor
            inscriptionGuid
            purchaseDate
            purchaseBy
            voucherGuid
            createdAt
            }
        }
    `;

    useEffect(() => {
        (async () => {
            const {data:{data:{GetVoucherInfo}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            
            setInscriptionDetailModal(getNewObject(GetVoucherInfo));
            setinscriptionDetailInitialData(getNewObject(GetVoucherInfo));
        })();

    }, []);

    return createPortal(
        <div className="ModalBackground">
            <div className="user-details-modal-main-container">
                <div className="user-details-modal-container">
                    <img src={closeIcon} className="user-detail-modal-close-btn" alt="close modal icon" onClick={onCloseUserDetails}/>   
                    <form className="user-detail-modal-form">
                        
                        <div className="user-detail-form-student-info">
                            <h2 className="user-detail-form-title"> Voucher Information</h2>
                            <div className="user-detail-student-info-block">
                                <div className="user-detail-student-info-line">
                                    <div className="user-detail-modal-item">
                                        <label for="productName" className="user-detail-modal-item-label">productName</label>
                                        <input className="user-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.productName} name="productName" />
                                    </div>
                                    <div className="user-detail-modal-item">
                                        <label for="productId" className="user-detail-modal-item-label">productId</label>
                                        <input className="user-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.productId} name="productId"/>
                                    </div>
                                    <div className="user-detail-modal-item">
                                        <label for="voucherGuid" className="user-detail-modal-item-label">voucherGuid</label>
                                        <input className="user-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.voucherGuid} name="voucherGuid"/>
                                    </div>
                                </div>
                                <div className="user-detail-student-info-line">
                                    <div className="user-detail-modal-item">
                                        <label for="value" className="user-detail-modal-item-label">value</label>
                                        <input className="user-detail-modal-item-value"  onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.value} name="value"/>
                                    </div>
                                    <div className="user-detail-modal-item">
                                        <label for="createdAt" className="user-detail-modal-item-label">createdAt</label>
                                        <input className="user-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.createdAt} name="createdAt"/>
                                    </div>
                                    <div className="user-detail-modal-item">
                                        <label for="voucherCost" className="user-detail-modal-item-label">Price</label>
                                        <input className="user-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.voucherCost} name="voucherCost"/>
                                    </div>
                                </div>
                                <div className="user-detail-student-info-line">
                                    <div className="user-detail-modal-item">
                                        <label for="purchaseDate" className="user-detail-modal-item-label">Purchase Date</label>
                                        <input className="user-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="date" value = {inscriptionDetailModal.purchaseDate} name="purchaseDate"/>
                                    </div>
                                    <div className="user-detail-modal-item">
                                        <label for="purchaseBy" className="user-detail-modal-item-label">Purchase by</label>
                                        <input className="user-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.purchaseBy} name="purchaseBy"/>
                                    </div>
                                    <div className="user-detail-modal-item">
                                        <label for="voucherVendor" className="user-detail-modal-item-label">voucher Vendor</label>
                                        <input className="user-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.voucherVendor} name="voucherVendor" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="user-detail-form-financial-info">
                            <h2 className="user-detail-form-title"> Inscription Information</h2>
                            <div className="user-detail-financial-info-block">
                                <div className="inscription-detail-modal-payment-grid">
                                    {/* <UserCoursesGrid
                                        dni = {dni}
                                    /> */}
                                </div>
                            </div>
                        </div>
                        
                        <div className="user-detail-form-btns">
                        {isDetailsUpdated ? <button className="user-detail-btn-save" onClick={handleSaveChanges}>Save Changes</button> : ""}
                            <button className="user-detail-btn-cancel" onClick={onCloseUserDetails}>Cancel</button>
                        </div>
                        
                    </form>
                </div>
            </div>
        </div>
        ,
        document.getElementById("modal")
    );
}

export {VoucherDetailModal};