import React, {useEffect} from "react";
import "./../../styles/courseStudents.css"

import { ToogleContext } from "../../hooks/ToogleContext";
import { InscriptionDetailsModal } from "./../../components/AdminPanel/InscriptionDetailsModal";
import { getGraphQl } from "../../hooks/ApiCalls";

import viewIcon from "./../../imgs/ActionsIcons/view.svg";
import moneyIcon from "./../../imgs/ActionsIcons/money.svg";
import paypalIcon from "./../../imgs/ActionsIcons/paypal.svg";
import confirmIcon from "./../../imgs/ActionsIcons/confirm.svg";
import cancelIcon from "./../../imgs/ActionsIcons/cancel.svg";
import { ProgressBar } from "../ProgressBar";


function CourseStudents ({name, lastname, email, progress, inscriptionGuid, courseStep}) {

    const {userLocation, setuserLocation} = React.useContext(ToogleContext);
    const [openInscriptionDetails, setopenInscriptionDetails] = React.useState(false);
    const [shouldOpenActionMenu, setshouldOpenActionMenu] = React.useState(false)

    const handleInscriptionActions = () => {
        setshouldOpenActionMenu(!shouldOpenActionMenu);
    };

    const handleCloseInscriptionDetails = () => {
        setopenInscriptionDetails(false);
    };

    const handleViewDetails = () => {
        setshouldOpenActionMenu(false);
        setopenInscriptionDetails(true);
    };

    const handleSendPaymentInfo = async (evt) => {
        let graphQuery = `
            mutation {
                SendPaymentInfo(PaymentInfo:{inscriptionGuid:"${inscriptionGuid}"}) {
                    inscriptionGuid
                }
            }
        `;
    
       let status = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
       if (status){window.alert("Payment Info Sent")}
    };

    const handleConfirmPayment = async (evt) => {
        let graphQuery = `
            mutation {
                confirmStudentInscriptionPayment(StudentInscription:{inscriptionGuid:"${inscriptionGuid}"}) {
                    paymentReceived
                }
            }
        `;
    
       let status = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
       if (status){
            window.alert("Student Payment Confirmed");
            window.location.reload(true);
        }
    };

    const handleConfirmInscription = async (evt) => {
        let graphQuery = `
            mutation {
                confirmStudentInscription(StudentInscription:{inscriptionGuid:"${inscriptionGuid}"}) {
                    InscriptionConfirmed
                }
           }
        `;
    
       let status = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
       if (status){
            window.alert("Student Inscription Confirmed");
            window.location.reload(true);
        }
    };

    const handleCancelInscription = async (evt) => {
        let graphQuery = `
            mutation {
                cancelStudentInscription(StudentInscription:{inscriptionGuid:"${inscriptionGuid}"}) {
                    InscriptionEnabled
                }
            }
        `;
    
       let status = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
       if (status){
            window.alert("Student Inscription Cancelled");
            window.location.reload(true);
        }
    };

    useEffect(() => {
        if (userLocation[1]) return
        setuserLocation([false,true,false,false,false,false]);
      }, []);

    return (
        <React.Fragment>
            <tr className="student-inscription-table-line">
                <td className="student-inscription-table-item">{name}</td>
                <td className="student-inscription-table-item">{lastname}</td>
                <td className="student-inscription-table-item">{email}</td>
                <td className="student-inscription-table-item">
                    <ProgressBar
                        progress={progress}
                    />
                </td>
                <td className="student-inscription-table-item">{courseStep}</td>
                <td className="student-inscription-table-actions-item" onClick={handleInscriptionActions}>Actions</td>
                    
            </tr> 
            <div className= {`student-inscription-action-menu ${shouldOpenActionMenu && "student-inscription-action-menu-open" } `}>
                    <div className="student-inscription-action-items-container">
                        <div className="student-inscription-action-item-container">
                            <li className="student-inscription-action-item" onClick={handleViewDetails}>View Details</li>
                            <img src={viewIcon} className="student-inscription-action-icon"/>
                        </div>

                        <div className="student-inscription-action-item-container">
                            <li className="student-inscription-action-item" onClick={handleSendPaymentInfo}>Send Payment Info</li>
                            <img src={paypalIcon} className="student-inscription-action-icon"/>
                        </div>

                        <div className="student-inscription-action-item-container">
                            <li className="student-inscription-action-item" onClick={handleConfirmPayment}>Confirm Payment</li>
                            <img src={moneyIcon} className="student-inscription-action-icon"/>
                        </div>

                        <div className="student-inscription-action-item-container">
                            <li className="student-inscription-action-item" onClick={handleConfirmInscription}>Confirm Inscription</li>
                            <img src={confirmIcon} className="student-inscription-action-icon"/>
                        </div>
                        
                        <div className="student-inscription-action-item-container">
                            <li className="student-inscription-action-item" onClick={handleCancelInscription}>Cancel Inscription</li>
                            <img src={cancelIcon} className="student-inscription-action-icon"/>
                        </div>

                    </div>
            </div>

            {openInscriptionDetails &&  <InscriptionDetailsModal inscriptionGuid= {inscriptionGuid} onCloseInscriptionDetails={handleCloseInscriptionDetails} />}
        </React.Fragment>

    );
}

export {CourseStudents};