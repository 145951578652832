import React, {useEffect, useState} from "react";
import "../../styles/productsView.css"

import { useNavigate } from "react-router-dom";
import { ToogleContext } from "../../hooks/ToogleContext";
import { ProductCard } from "./ProductCard";

import { getGraphQl } from "../../hooks/ApiCalls";

function ProductsView () {

    const {productList, setproductList} = React.useContext(ToogleContext);
    const {userLocation, setuserLocation} = React.useContext(ToogleContext);
    
    let graphQuery = `
        query{
            Products {
            id
            name
            productId
            description
            price
            image
            enabled
            }
        }
    `;

    useEffect(() => {

        setuserLocation([false,false,false,false,true,false,false]);
        (async () => {
            const {data:{data:{Products}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            setproductList(Products);
        })();

      }, []);

    return (
        <div className="product-view-main-container">
            {
                productList.map( (product) => ( 
                    <ProductCard 
                        key={product.id} 
                        productIcon = {product.image}
                        productName = {product.name}
                        productDescription= {product.description} 
                        productId= {product.productId}
                    /> 
                ))
            }
        </div>
    );

}

export { ProductsView };