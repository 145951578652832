import React, {useEffect, useState} from "react";
import "../../styles/studentProgressView.css"

import courseIcon from "../../imgs/github.svg";
import { ToogleContext } from "../../hooks/ToogleContext";
import { StudentProgress } from "./StudentsProgress";

import { getGraphQl } from "../../hooks/ApiCalls";

function StudentProgressView () {

    const {inscriptionsList, setinscriptionsList} = React.useContext(ToogleContext);
    
    let graphQuery = `
        query{
            GetConfirmedInscriptions {
            id
            name
            lastname
            email
            phone
            courseName
            inscriptionGuid
            } 
        }
    `;

    useEffect(() => {

        (async () => {
            const {data:{data:{GetConfirmedInscriptions}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            setinscriptionsList(GetConfirmedInscriptions);
        })();

      }, []);

    return (
        <div className="student-progress-view-main-container">
            <table className="student-progress-table">
                <tr>
                    <td className="student-progress-table-colum">Name</td>
                    <td className="student-progress-table-colum">Lastname</td>
                    <td className="student-progress-table-colum">Progress</td>
                    <td className="student-progress-table-colum">Phone</td>
                    <td className="student-progress-table-colum">Course Name</td>
                    <td className="student-progress-table-colum">Actions</td>
                </tr>
                {   
                    inscriptionsList.map( (inscription) => ( 
                        
                        <StudentProgress 
                            key={inscription.id} 
                            name = {inscription.name}
                            lastname = {inscription.lastname}
                            email = {inscription.email}
                            phone={inscription.phone}
                            courseName= {inscription.courseName} 
                            inscriptionGuid= {inscription.inscriptionGuid}
                        /> 
                    ))
                }
                
            </table>
        </div>
    );

}

export { StudentProgressView };