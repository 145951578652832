import { useState } from "react";

export default function useAccessLevel(){

    const getAccessLevel = () => {
        const accessLevelString = localStorage.getItem("agilexcellence.io:Token");
        const accessLevel = JSON.parse(accessLevelString);
        return accessLevel?.access_level
    };

    const [access_level, setaccess_level] = useState(getAccessLevel());

    return {
        access_level: access_level
    }
}
