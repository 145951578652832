import React from "react";
import {useEffect, useState} from "react";
import "./../../styles/myCourses.css";

import { getGraphQl } from "../../hooks/ApiCalls";
import { MyCourseCard } from "../../components/Trainers/MyCourseCard";
import { ToogleContext } from "../../hooks/ToogleContext";
import { SectionTitle } from "../../components/SectionTitle";
import { TrainerNavbar } from "../../components/Trainers/TrainerNavbar";

function MyTrainerCourses () {

    const {trainerCourseList, settrainerCourseList} = React.useContext(ToogleContext);
    
    let graphQuery = `
        query{
            GetLearningCoursesOpen {
              id
              courseIdentifier
              name
              productId
              learningCourseId
              startDate
              endDate
              enabled
            }
          }
    `;

    useEffect(() => {

        (async () => {
            const {data:{data:{GetLearningCoursesOpen}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            settrainerCourseList(GetLearningCoursesOpen);
            console.log(GetLearningCoursesOpen);
        })();

      }, []);

    return(
        <React.Fragment>
            <TrainerNavbar/>
            <div className="my-courses-main-container">
                <div className="my-course-cards-wrap">
                
                    {    
                        trainerCourseList.map( (course) => ( 
                            <MyCourseCard 
                                key={course.id} 
                                name = {course.name} 
                                startDate = {course.startDate} 
                                endDate={course.endDate}
                                learningCourseId={course.learningCourseId}
                                courseIdentifier= {course.courseIdentifier}
                            /> 
                        ))
                    }
                </div>
            </div>
        </React.Fragment>
    );
}

export {MyTrainerCourses};