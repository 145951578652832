import React, {useEffect, useState} from "react";
import "../../styles/usersView.css"

import courseIcon from "../../imgs/github.svg";
import { ToogleContext } from "../../hooks/ToogleContext";

import { getGraphQl } from "../../hooks/ApiCalls";
import { Users } from "./Users";


function UsersView () {

    const {usersList, setusersList} = React.useContext(ToogleContext);
    
    let graphQuery = `
        query{
            Users{
            id
            dni
            name
            lastname
            country
            email
            access_level
            enabled
            }
        }
    `;

    useEffect(() => {

        (async () => {
            const {data:{data:{Users}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            setusersList(Users);
        })();

      }, []);

    return (
        <div className="users-view-main-container">
            <table className="users-table">
                <tr>
                    <td className="users-table-colum">Name</td>
                    <td className="users-table-colum">Lastname</td>
                    <td className="users-table-colum">Email</td>
                    <td className="users-table-colum">Country</td>
                    <td className="users-table-colum">Role</td>
                    <td className="users-table-colum">Enabled</td>
                    <td className="users-table-colum">Actions</td>
                </tr>
                {   
                    usersList.map( (user) => ( 
                        <Users 
                            key={user.id} 
                            name = {user.name}
                            lastname = {user.lastname}
                            email= {user.email}
                            country = {user.country}
                            accessLevel = {user.access_level}
                            enabled={user.enabled}
                            dni= {user.dni}
                        /> 
                    ))
                }
                
            </table>
        </div>
    );

}

export { UsersView };