import React,{useEffect,useCallback} from "react";
import  {createPortal}  from "react-dom";
import "../../styles/AddVoucherForm.css";

import {getGraphQl} from "../../hooks/ApiCalls";

function AddVoucherForm ({onCloseAddVoucherFormClick}) {

    const [values, setValues] = React.useState({
        productId: "",
        value: "",
        purchaseDate: "",
        purchaseBy: "",
        voucherCost: "",
        voucherVendor: "",
      });

      const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
          onCloseAddVoucherFormClick();
        }
      }, []);
    
      useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
      }, [escFunction]);
    
      let graphQuery= `
            mutation {
              createVouchers(Vouchers:{value:"${values.value}", productId:"${values.productId}",purchaseDate:"${values.purchaseDate}", purchaseBy:"${values.purchaseBy}", voucherCost:"${values.voucherCost}", voucherVendor:"${values.voucherVendor}" }) {
                message
                status
              }
            }
        `;
    
      function handleChange(evt) {
        const { target } = evt;
        const { name, value } = target;
    
        const newValues = {
          ...values,
          [name]: value,
        };
        setValues(newValues);
      }
      
      const handleSubmit = async (evt) => {
        evt.preventDefault();
        const {data:{data:{createVouchers:{status}}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery})
        if (status ==200) {
          alert("Voucher created successfuly");
        } else {
          alert("Voucher was not created, it already exists")
        }
        window.location.reload();
      }

    return createPortal(
          <div className="ModalBackground">
              <div className="add-voucher-form-main-container" onSubmit={handleSubmit}>
                  <div className="add-voucher-form-container">
                      <h1 className="add-voucher-form-title">Add new voucher</h1>
                      <form className="add-voucher-form-inner-container">

                          <div className="add-voucher-form-items">
                              <input className="add-voucher-form-item" name= "productId" type="text" placeholder="productId" value={values.productId} onChange={handleChange} required />
                              <input className="add-voucher-form-item" name= "value" type="text" placeholder="value" value={values.value} onChange={handleChange} required />
                              <input className="add-voucher-form-item" name= "purchaseDate" type="date" placeholder="Purchase Date" value={values.purchaseDate} onChange={handleChange} required />
                              <input className="add-voucher-form-item" name= "purchaseBy" type="text" placeholder="Purchese By" value={values.purchaseBy} onChange={handleChange} required/>
                              <input className="add-voucher-form-item" name= "voucherCost" type="text" placeholder="Voucher Cost" value={values.voucherCost} onChange={handleChange} required/>
                              <input className="add-voucher-form-item" name= "voucherVendor" type="text" placeholder="Vendor" value={values.voucherVendor} onChange={handleChange} required/>
                          </div>
                          
                          <div className="add-voucher-btns">
                              <button className="add-voucher-btn-create" type="submit"> Create Voucher </button>
                              <button className="add-voucher-btn-cancel" onClick={onCloseAddVoucherFormClick}> Cancel </button>
                          </div>
                      </form>
                  </div>
              </div>
            </div>
            ,
            document.getElementById("modal")
    );
}

export {AddVoucherForm};