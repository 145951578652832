import React, {useEffect, useState} from "react";
import "../../styles/studentInscriptionView.css"

import courseIcon from "../../imgs/github.svg";
import { ToogleContext } from "../../hooks/ToogleContext";
import { StudentInscription } from "./StudentInscription";

import { getGraphQl } from "../../hooks/ApiCalls";

function StudentInscriptionView () {

    const {inscriptionsList, setinscriptionsList} = React.useContext(ToogleContext);
    
    let graphQuery = `
        query{
            GetUnconfirmedInscriptions {
            id
            name
            lastname
            email
            phone
            courseName
            inscriptionGuid
            } 
        }
    `;

    useEffect(() => {

        (async () => {
            const {data:{data:{GetUnconfirmedInscriptions}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            setinscriptionsList(GetUnconfirmedInscriptions);
        })();

      }, []);

    return (
        <div className="student-inscription-view-main-container">
            <table className="student-inscription-table">
                <tr>
                    <td className="student-inscription-table-colum">Name</td>
                    <td className="student-inscription-table-colum">Lastname</td>
                    <td className="student-inscription-table-colum">Email</td>
                    <td className="student-inscription-table-colum">Phone</td>
                    <td className="student-inscription-table-colum">Course Name</td>
                    <td className="student-inscription-table-colum">Actions</td>
                </tr>
                {   
                    inscriptionsList.map( (inscription) => ( 
                        
                        <StudentInscription 
                            key={inscription.id} 
                            name = {inscription.name}
                            lastname = {inscription.lastname}
                            email = {inscription.email}
                            phone={inscription.phone}
                            courseName= {inscription.courseName} 
                            inscriptionGuid= {inscription.inscriptionGuid}
                        /> 
                    ))
                }
                
            </table>
        </div>
    );

}

export { StudentInscriptionView };