import React, {useEffect} from "react";
import "./../../styles/users.css"

import { ToogleContext } from "../../hooks/ToogleContext";
import { UserDetailModal } from "./UserDetailModal";
import { getGraphQl } from "../../hooks/ApiCalls";

import viewIcon from "./../../imgs/ActionsIcons/view.svg";
import moneyIcon from "./../../imgs/ActionsIcons/money.svg";
import paypalIcon from "./../../imgs/ActionsIcons/paypal.svg";
import confirmIcon from "./../../imgs/ActionsIcons/confirm.svg";
import cancelIcon from "./../../imgs/ActionsIcons/cancel.svg";


function Users ({name, lastname, email, country, accessLevel, enabled, dni}) {

    const {userLocation, setuserLocation} = React.useContext(ToogleContext);
    const [openUserDetails, setopenUserDetails] = React.useState(false);
    const [shouldOpenActionMenu, setshouldOpenActionMenu] = React.useState(false)

    const handleUserActions = () => {
        setshouldOpenActionMenu(!shouldOpenActionMenu);
    };

    const handleCloseUserDetails = () => {
        setopenUserDetails(false);
    };

    const handleViewDetails = () => {
        setshouldOpenActionMenu(false);
        setopenUserDetails(true);
    };

    useEffect(() => {
        setuserLocation([false,false,false,false,false,true,false,false]);
      }, []);

    return (
        <React.Fragment>
            <tr className="users-table-line">
                <td className="users-table-item">{name}</td>
                <td className="users-table-item">{lastname}</td>
                <td className="users-table-item">{email}</td>
                <td className="users-table-item">{country}</td>
                <td className="users-table-item">{accessLevel == "f84bb637-59ee-445d-b743-9e93cc54c917"? "Estudiante": accessLevel == "e62b645e-feca-49c4-b7ee-70b28dee2d9a" ? "Sales" : accessLevel == "4444fa33-cfd1-43ec-b8da-a1dfa96124c1" ? "Trainer" : accessLevel == "356464fa-ea81-4921-a4f7-c4d6f1764855" ? "admin" : "None" }</td>
                <td className="users-table-item">{enabled}</td>
                <td className="users-table-actions-item" onClick={handleUserActions}>Actions</td>
                    
            </tr> 
            <div className= {`users-action-menu ${shouldOpenActionMenu && "users-action-menu-open" } `}>
                    <div className="users-action-items-container">
                        <div className="users-action-item-container">
                            <li className="users-action-item" onClick={handleViewDetails}>View Details</li>
                            <img src={viewIcon} className="users-action-icon"/>
                        </div>

                    </div>
            </div>

            {openUserDetails &&  <UserDetailModal dni= {dni} onCloseUserDetails={handleCloseUserDetails} />}
        </React.Fragment>

    );
}

export {Users};