export const isObject = (obj) => {
    return Object.prototype.toString.call(obj) === '[object Object]';
};
  
export const isTheSameObject = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        let val1 = obj1[key];
        let val2 = obj2[key];
        let areObjects = isObject(val1) && isObject(val2);

        if ((areObjects && !isTheSameObject(val1, val2)) || (!areObjects && val1 !== val2)) {
        return false;
        }
    }
    return true;
};

export const getNewObject = (object) => {
    const newObject = {};
    for (const property in object) {
      if(object[property] === null) {
        newObject[property] = "";
      }else {
          newObject[property] = object[property];
      }
    }
    return newObject;
}

export const buildMutationBody = (object) => {
    let string = "";
    for (const property in object) {
      if(typeof object[property] === "boolean" || typeof object[property]  === "number") {
        string = string + `${property}: ${object[property]},` 
      } else {
          string = string + `${property}: "${object[property]}",`  
      }
    }
    return `{${string}}`
  }

// const person3 = {
//     name: 'David',
//     age: 'Ortega',
//     family: {
//         dad: 'Rodrigo',
//         mom: 'Estela',
//     },
// };

// const person4 = {
//     name: 'David',
//     age: 'Ortega',
//     family: {
//         dad: 'Rodrigo',
//         mom: 'Estela',
//     },
//};

//console.log(isTheSameObject(person4, person3)); //true