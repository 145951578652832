import React, {useEffect,useState} from "react";

import {getGraphQl} from "../../hooks/ApiCalls";

function SelectPendingToVoucher ({onChange,name}) {

    const [selectValues, setselectValues] = useState([]);

    let graphQuery = `
        query {
            GetInscriptionPendingVoucher {
            name
            lastname
            courseName
            inscriptionGuid
            }
        }
    `;

    useEffect(() => {
        (async () => {
            const {data:{data:{GetInscriptionPendingVoucher}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            setselectValues(GetInscriptionPendingVoucher);
        })();

      }, []);

    return (
        <React.Fragment>
                <select onChange={onChange} name={name}>
                    {selectValues.map(({inscriptionGuid,name,lastname, courseName}) => {
                        return <option value={inscriptionGuid}>{name} {lastname} [ {courseName} ]</option>
                    })}
                </select>
        </React.Fragment>
    );

}

export {SelectPendingToVoucher};