import React, {useState, useEffect} from "react";
import "./../../styles/loginAsView.css";

import logo from "./../../imgs/Brand/logo-alterno.png";
import adminIcon from "./../../imgs/CourseIcons/1.png";
import studentIcon from "./../../imgs/CourseIcons/2.png";
import trainerIcon from "./../../imgs/CourseIcons/3.png";

import { LoginAsCard } from "./LoginAsCard";
import { getGraphQl } from "../../hooks/ApiCalls";
import { useNavigate } from "react-router-dom";
import useAccessLevel from "../../hooks/useAccessLevel";
import useToken from "../../hooks/useToken";

function LoginAsView () {

    const navigate = useNavigate();
    const {access_level, setaccess_level} = useAccessLevel();
    const {token, setToken} = useToken();
    const [name, setname]= useState("");

    const handleLogOut = () => {
        localStorage.removeItem('agilexcellence.io:Token');
        window.location.reload(true);
    };
    
    let graphQuery = `
        query {
            GetUsersInfoByToken(Users:{token:"${token}"}) {
                nickname
              }
          }
    `;

    useEffect(() => {

        (async () => {
            const {data:{data:{GetUsersInfoByToken}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            setname (GetUsersInfoByToken.nickname);
        })();

      }, []);
    
    return (
        <div className="login-as-view-main-container">
            <img src={logo} className="login-as-view-logo" alt="pisoco academy logo"/>
            <h1 className="login-as-view-user-txt"> Welcome, {name}</h1>
            <div className="login-as-view-cards">
                {   access_level== "356464fa-ea81-4921-a4f7-c4d6f1764855" || access_level == "e62b645e-feca-49c4-b7ee-70b28dee2d9a" ?
                    <LoginAsCard
                        roleName = "Administrator"
                        roleIcon = {adminIcon}
                        navigateRoute = "/students-inscriptions"
                    />
                    :
                    <div></div>
                }
                {
                    access_level== "356464fa-ea81-4921-a4f7-c4d6f1764855" || access_level == "e62b645e-feca-49c4-b7ee-70b28dee2d9a" || access_level== "f84bb637-59ee-445d-b743-9e93cc54c917" ?
                    <LoginAsCard
                        roleName = "Student"
                        roleIcon = {studentIcon}
                        navigateRoute = "/students"
                    />
                    :
                    <div></div>
                }
                {
                    access_level== "356464fa-ea81-4921-a4f7-c4d6f1764855" || access_level== "4444fa33-cfd1-43ec-b8da-a1dfa96124c1" ?
                    <LoginAsCard
                        roleName = "Trainer"
                        roleIcon = {trainerIcon}
                        navigateRoute = "/trainer"
                    />
                    :
                    <div></div>
                }
            </div>
            <h3 className="login-as-view-logout-btn" onClick={() => { navigate("/"); handleLogOut();}}>Logout</h3>
            
        </div>
    );

}

export { LoginAsView };