import React, {useContext,useEffect, useState} from "react";
import "./../../styles/myStudentCourseInfo.css";

import { StudentsNavbar } from "../../components/Students/StudentsNavbar";
import { useParams } from "react-router-dom";
import { getGraphQl } from "../../hooks/ApiCalls";

import mapIcon from "./../../imgs/StudentsIcons/map.svg";
import moduleIcon from "./../../imgs/StudentsIcons/modules.svg";
import contentIcon from "./../../imgs/StudentsIcons/content.svg";
import rowIcon from "./../../imgs/StudentsIcons/topright-row.svg";
import rowGreenIcon from "./../../imgs/StudentsIcons/topright-row-green.svg";
import clockIcon from "./../../imgs/ActionsIcons/time.svg";

import { PSM1 } from "../../components/Students/Readings/PSM1";
import { CourseContentAcordion } from "../../components/Students/CourseContentAcordion";

import useToken from "../../hooks/useToken";
import useAccessLevel from "../../hooks/useAccessLevel";

function MyStudentCourseInfo () {

    let learningCourseId = useParams();

    const {access_level, setaccess_level} = useAccessLevel();
    const {token, setToken} = useToken();
    const [courseInfo, setcourseInfo] = useState("");
    const [name, setname] = useState("");
    const [LearningCourseModules, setLearningCourseModules] = useState([]);

    let graphQueryUserInfo = `
        query {
            GetUsersInfoByToken(Users:{token:"${token}"}) {
                nickname
            }
        }
    `;

    useEffect(() => {

        (async () => {
            const {data:{data:{GetUsersInfoByToken}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQueryUserInfo});
            setname (GetUsersInfoByToken.nickname);
        })();

      }, []);

    
    let graphQuery = `
        query
        {
            GetLearningCoursesbylearningCourseId(LearningCourses:{learningCourseId:"${learningCourseId.learningCourseId}"}) {
                id
                name
                coursePPT
                courseAgenda
                courseWhatsapp
                courseIdentifier
                courseYoutubeList
                difficulty
                courseType
                courseDuration
            }
        }
    `;

    let graphQueryModules = `
        query
        {
            GetLearningCourseModules (LearningCourseModules:{learningCourseId:"${learningCourseId.learningCourseId}"}) {
                moduleName
                moduleOrder
                moduleProgress
                learningCourseModuleId
            }
        }
    `;

    useEffect(() => {

        (async () => {
            const {data:{data:{GetLearningCoursesbylearningCourseId}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            const {data:{data:{GetLearningCourseModules}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQueryModules});
            setcourseInfo(GetLearningCoursesbylearningCourseId);
            setLearningCourseModules(GetLearningCourseModules)
        })();

      }, []);

    return(
        <React.Fragment>
            <StudentsNavbar/>
            <div className="my-student-course-info-main-container">
                <div className="my-student-course-info-inner-container">
                    <p className="my-student-course-info-welcome-text">Hola {name}, bienvenido al curso</p>

                    <div className="my-student-course-info-welcome-banner-container">
                        <div className="my-student-course-info-welcome-banner-inner-container">
                            <div className="my-student-course-info-welcome-banner-video-container">
                                <iframe width="100%" height="200" src={`http://www.youtube.com/embed?listType=playlist&list=${courseInfo.courseYoutubeList}&rel=0&modestbranding=0&showinfo=0`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; " allowfullscreen></iframe>
                            </div>
                            <div className="my-student-course-info-welcome-banner-course-container">
                                <h1 className="my-student-course-info-welcome-banner-course-name"> {courseInfo.name}</h1>
                                <div className="my-student-course-info-welcome-banner-labels-container">
                                    <label className="my-student-course-info-welcome-banner-label">{courseInfo.difficulty}</label>
                                    <label className="my-student-course-info-welcome-banner-label">{courseInfo.courseType}</label>
                                </div>
                                <div className="my-student-course-info-welcome-banner-time-container">
                                    <img src= {clockIcon} className="my-student-course-info-welcome-banner-time-icon"/>
                                    <h1 className="my-student-course-info-welcome-banner-time-text">{courseInfo.courseDuration} mins</h1>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="my-student-course-info-core-container">

                        <div className="my-student-course-info-core-left-container">
                            <div className="my-student-course-info-core-left-header-container">
                                <img src= {mapIcon} className="my-student-course-info-core-left-icon"/>
                                <h1 className="my-student-course-info-core-subtitle"> Contenido</h1>
                            </div>

                            {    
                                LearningCourseModules.map( (courseModule) => ( 
                                    <CourseContentAcordion 
                                        key={courseModule.id} 
                                        moduleName = {courseModule.moduleName}
                                        learningCourseModuleId= {courseModule.learningCourseModuleId}
                                        moduleOrder = {courseModule.moduleOrder}
                                        moduleProgress = {courseModule.moduleProgress}
                                    /> 
                                ))
                            }
                            
                        </div>


                        <div className="my-student-course-info-core-right-container">
                            <h1 className="my-student-course-info-core-subtitle"> Quick Links</h1>
                            <a href={courseInfo.courseWhatsapp} target="__blank" className="my-student-course-info-core-right-btn-link"><button className="my-student-course-info-core-right-btn">Ir a whatsapp <img src={rowIcon} className="my-student-course-info-core-icon"/> </button></a>

                            <a href="https://quizapp.agilexcellence.io/" target="__blank" className="my-student-course-info-core-simulator-btn-link"><button className="my-student-course-info-core-simulator-btn">Ax Simulator <img src={rowGreenIcon} className="my-student-course-info-core-icon"/> </button></a>
                            
                            <div className="my-student-course-info-core-progress-container">
                                <div className="my-student-course-info-core-progress-header">
                                    <h1 className="my-student-course-info-core-progress-subtitle"> Your progress</h1>
                                    <div></div>
                                </div>

                                <div className="my-student-course-info-core-progress-visuals-container">
                                    <div className="my-student-course-info-core-progress-visual-item">
                                        <img src={contentIcon} className="my-student-course-info-core-progress-visual-icon"/>
                                        <div className="my-student-course-info-core-progress-data">
                                            <h2 className="my-student-course-info-core-progress-number">33%</h2>
                                            <h4 className="my-student-course-info-core-progress-category">Contenido</h4>
                                        </div>
                                    </div>
                                    <div className="my-student-course-info-core-progress-visual-item">
                                        <img src={moduleIcon} className="my-student-course-info-core-progress-visual-icon"/>
                                        <div className="my-student-course-info-core-progress-data">
                                            <h2 className="my-student-course-info-core-progress-number">1/3</h2>
                                            <h4 className="my-student-course-info-core-progress-category">Modulos</h4>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>

                            
                            {/* {   courseInfo.courseIdentifier == "PSMI" ?
                                <PSM1/>
                                :
                                ""
                            } */}

                        </div>
                    </div>
                    
                    {/* <div className="my-student-course-info-presentation-container">
                        <div className="my-student-course-info-presentation-left-box">
                            <h1 className="my-student-course-info-title-presentation-box">Material del curso</h1>
                        </div>
                        <iframe src={`https://docs.google.com/presentation/d/e/${courseInfo.coursePPT}/embed?start=true&loop=false&delayms=10000`} frameborder="0" width="960" height="569" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                    </div> */}

                </div>
            </div>

        </React.Fragment>
    );
}

export { MyStudentCourseInfo };