import React from "react";
import "../../../styles/moduleContent.css";

import backArrow from "../../../imgs/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg";

function ModuleContent ({name}) {
    return (
        <div className="module-content-container" >
            <img src={backArrow} className="module-content-img" alt="content type icon indicator"/>
            <h2 className="module-content-text">{name}</h2>
        </div>
    );
}

export {ModuleContent};