import React, {useState,useEffect} from "react";
import "./../../styles/myOpenInscriptionCoursesView.css";

import { MyOpenInscriptionCoursesCard } from "./MyOpenInscriptionCoursesCard";

import useToken from "../../hooks/useToken";
import { getGraphQl } from "../../hooks/ApiCalls";

function MyOpenInscriptionCoursesView () {

    const [openInscriptionList, setopenInscriptionList] = useState([]);
    const {token, setToken} = useToken();
    
    let graphQuery = `
        query
            {
            GetMyOpenInscriptionCourses(LearningCourses:{token:"${token}"}) {
            id
            name
            learningCourseId
            startDate
            endDate
            courseIdentifier
            }
        }
    `;

    useEffect(() => {

        (async () => {
            const {data:{data:{GetMyOpenInscriptionCourses}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            setopenInscriptionList(GetMyOpenInscriptionCourses);
        })();

      }, []);

    return (
        <div className="my-open-inscriptions-course-view-main-container">
            <div className="my-open-inscriptions-course-view-inner-container">
                <h2 className="my-open-inscriptions-course-view-title">Próximos cursos</h2>
                <div className="my-open-inscriptions-course-view-cards-container">
                    {    
                        openInscriptionList.map( (course) => ( 
                            <MyOpenInscriptionCoursesCard 
                                key={course.id} 
                                name = {course.name} 
                                startDate = {course.startDate} 
                                endDate={course.endDate}
                                learningCourseId={course.learningCourseId}
                                courseIdentifier= {course.courseIdentifier}
                                token={token}
                            /> 
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export {MyOpenInscriptionCoursesView};