import React, {useEffect,useCallback} from "react";
import  {createPortal}  from "react-dom";
import "./../../styles/courseInfoContent.css";

import { getGraphQl } from "../../hooks/ApiCalls";
import { isTheSameObject, getNewObject,buildMutationBody } from "../../utils/utils";

function CourseInfoContent ({learningCourseId}) {

    const [inscriptionDetailModal, setInscriptionDetailModal] = React.useState("");
    const [inscriptionDetailInitialData,setinscriptionDetailInitialData] = React.useState("");
    const [isDetailsUpdated, setisDetailsUpdated] = React.useState(false);

    function handleInscriptionDetailChange(evt) {
        const { target } = evt;
        const { name, value } = target;
    
        const newValues = {
          ...inscriptionDetailModal,
          [name]: value,
        };

        setInscriptionDetailModal(newValues);
        
        if (!isTheSameObject(newValues,inscriptionDetailInitialData)){
            setisDetailsUpdated(true);
        } else {
            setisDetailsUpdated(false);
        }

    }

    const handleSaveChanges = async (evt) => {
        evt.preventDefault();

        let auxObj = inscriptionDetailModal;
        delete auxObj.password;
        delete auxObj.courseInscriptionDate;
        auxObj.learningCourseId = learningCourseId.learningCourseId;

        let SaveChangesQuery = `
            mutation {
                updateLearningCourses(LearningCourses:
                    ${buildMutationBody(auxObj)}
                ) {
                id
                }
            }
        `;

        console.log("to update", SaveChangesQuery)

        let status = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: SaveChangesQuery});
        if (status){window.alert("Chnages Saved")}
    };

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            
        }
      }, []);
    
      useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
      }, [escFunction]);

    let graphQuery = `
        query {
            GetLearningCoursesbylearningCourseId(LearningCourses:{learningCourseId:"${learningCourseId.learningCourseId}"}) {
            name
            courseIdentifier
            productId
            startDate
            endDate
            coursePPT
            courseWhatsapp
            courseAgenda
            enabled
            }
        }
    `;

    useEffect(() => {
        (async () => {
            const {data:{data:{GetLearningCoursesbylearningCourseId}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});

            setInscriptionDetailModal(getNewObject(GetLearningCoursesbylearningCourseId));
            setinscriptionDetailInitialData(getNewObject(GetLearningCoursesbylearningCourseId));
        })();

    }, []);

    return createPortal(
        <div className="ModalBackground">
            <div className="inscription-details-modal-main-container">
                <div className="inscription-details-modal-container">
                     
                    <form className="inscription-detail-modal-form">
                        
                        <div className="inscription-detail-form-student-info">
                            <h2 className="inscription-detail-form-title"> Course Information</h2>
                            <div className="inscription-detail-student-info-block">
                                <div className="inscription-detail-student-info-line">
                                    <div className="inscription-detail-modal-item">
                                        <label for="name" className="inscription-detail-modal-item-label">Name</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.name} name="name" />
                                    </div>
                                    <div className="inscription-detail-modal-item">
                                        <label for="lastname" className="inscription-detail-modal-item-label">Identificador</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.courseIdentifier} name="courseIdentifier"/>
                                    </div>
                                    <div className="inscription-detail-modal-item">
                                        <label for="dni" className="inscription-detail-modal-item-label">ProductId</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.productId} name="productId"/>
                                    </div>
                                </div>
                                <div className="inscription-detail-student-info-line">
                                    <div className="inscription-detail-modal-item">
                                        <label for="email" className="inscription-detail-modal-item-label">startDate</label>
                                        <input className="inscription-detail-modal-item-value"  onChange={handleInscriptionDetailChange} type="date" value = {inscriptionDetailModal.startDate} name="startDate"/>
                                    </div>
                                    <div className="inscription-detail-modal-item">
                                        <label for="phone" className="inscription-detail-modal-item-label">endDate</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="date" value = {inscriptionDetailModal.endDate} name="endDate"/>
                                    </div>
                                    <div className="inscription-detail-modal-item">
                                        <label for="address" className="inscription-detail-modal-item-label">finishDate</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="date" value = {inscriptionDetailModal.finishDate} name="finishDate"/>
                                    </div>
                                </div>
                                <div className="inscription-detail-student-info-line">
                                    <div className="inscription-detail-modal-item">
                                        <label for="email" className="inscription-detail-modal-item-label">courseProgress</label>
                                        <input className="inscription-detail-modal-item-value"  onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.courseProgress} name="courseProgress"/>
                                    </div>
                                    <div className="inscription-detail-modal-item">
                                        <label for="phone" className="inscription-detail-modal-item-label">Exams Presented</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.examsPresented} name="examsPresented"/>
                                    </div>
                                    <div className="inscription-detail-modal-item">
                                        <label for="address" className="inscription-detail-modal-item-label">enabled</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.enabled} name="enabled"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                                                
                        <div className="inscription-detail-form-btns">
                        {isDetailsUpdated ? <button className="inscription-detail-btn-save" onClick={handleSaveChanges}>Save Changes</button> : ""}
                            <button className="inscription-detail-btn-cancel">Cancel</button>
                        </div>
                        
                    </form>
                </div>
            </div>
        </div>
        ,
        document.getElementById("modal")
    );
}

export {CourseInfoContent};