import React, { useState } from "react";
import logo from "./../../imgs/Brand/logo-main.webp";
// import LoginPage from "../LoginPage";
import { useNavigate } from "react-router-dom";

import { LeftMenu } from "./../../components/AdminPanel/LeftMenu";
import { OpenCoursesView } from "./../../components/AdminPanel/OpenCoursesView";

import "./../../styles/dashboardPage.css";

import { AddButtom } from "../../components/AdminPanel/AddButtom";
import { LogoutButtom } from "../../components/AdminPanel/LogoutButtom";
import  { AddCourseForm } from "../../components/AdminPanel/AddCourseForm";

function Dashboard() {

  const [shouldShowOpenCoursesView, setshouldShowOpenCoursesView] =useState(false);

  const toogleAddButtomClick = () => {
    setshouldShowOpenCoursesView(!shouldShowOpenCoursesView);
  };

  const navigate = useNavigate();
  
  return (
    <React.Fragment>
      <div className="dashboardPage-main-container">
      
        <div className="dashboardPage-left-panel">
          <div className="dashboardPage-left-panel-header">
            <img src={logo} className="dashboardPage-logo" title="agile excellence main logo" alt="agilexcellence logo" onClick ={() => navigate("/")}/>
            <LeftMenu/>
          </div>
          <LogoutButtom/>
        </div>

        <div className="dashboardPage-right-panel">
          <AddButtom
            btn_name="New course"
            onClick={toogleAddButtomClick}
          />
          <OpenCoursesView/>
          {shouldShowOpenCoursesView && <AddCourseForm onCloseAddCourseFormClick={toogleAddButtomClick}/>}
        
        </div>

      </div>
      
    </React.Fragment>
  );
}

export {Dashboard};