import React, {useEffect,useState} from "react";
import "../../styles/settingCard.css";


import { ToogleButtom } from "./ToogleButtom";
import { CourseDetailsModal } from "./CourseDetailsModal";

function SettingCard ({courseIcon, courseIdentifier, courseName, inscriptionEnable, learningCourseId}) {

  const [openCourseDetails, setopenCourseDetails] = useState(false);

  const handleViewDetails = () => {
    setopenCourseDetails(true);
  };

  const handleCloseCourseDetails = () => {
    setopenCourseDetails(false);
  };

    return (
      <React.Fragment>

        <div className="open-course-card-panel-main-container">
            <div className="open-course-card-header">
              <div className="open-course-card-header-info">
                <img src={courseIcon} className="open-course-card-header-icon" alt="icono del curso"/>
                <h4 className="open-course-card-header-info-name"> {courseIdentifier}</h4>
              </div>
              <div className="open-course-card-header-info-toogle">
                <ToogleButtom
                  DBToogleValue= {inscriptionEnable}
                  learningCourseId = {learningCourseId}
                />
              </div>
            </div>
            <div className="open-course-card-description"><p> {courseName}</p></div>
            <div className="open-course-card-view-detail-btn" onClick={handleViewDetails}><h3>View details</h3></div>
          </div>

        {openCourseDetails &&  <CourseDetailsModal learningCourseId= {learningCourseId} onCloseInscriptionDetails={handleCloseCourseDetails} />}
      </React.Fragment>
    );
}

export { SettingCard };