import React from "react";
import Login from "../components/Login";

import useToken from "../hooks/useToken";
import { LoginAs } from "./LoginAs";

export default function LoginPage() {

  const {token, setToken} = useToken();

  if (!token){
    return <Login/>
  }

  return (
    <LoginAs/>
  );
  
}