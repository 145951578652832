import React, {useEffect, useState}  from "react";
import "../../styles/myStudentCourses.css";

import { StudentsNavbar } from "../../components/Students/StudentsNavbar";
import { getGraphQl } from "../../hooks/ApiCalls";
import { ToogleContext } from "../../hooks/ToogleContext";

import { MyCourseCard } from "./../../components/Students/MyCourseCard";

import useAccessLevel from "../../hooks/useAccessLevel";
import useToken from "../../hooks/useToken";


function MyStudentCourses () {

    const {studentCourseList, setstudentCourseList} = React.useContext(ToogleContext);
    const {access_level, setaccess_level} = useAccessLevel();
    const {token, setToken} = useToken();

    const [tabSelected, settabSelected] = useState([true,false])

    const handleSelectTab1 = () => {
        settabSelected([true,false]);
    }

    const handleSelectTab2 = () => {
        settabSelected([false,true]);
    }
    
    let graphQuery = `
            query
            {
            GetMyStudentCourses(MyStudentCourses:{token:"${token}"}) {
                startDate
                endDate
                courseName
                learningCourseId
                inscriptionGuid
                courseIdentifier
            }
        }
    `;

    useEffect(() => {

        (async () => {
            const {data:{data:{GetMyStudentCourses}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            setstudentCourseList(GetMyStudentCourses);
        })();

      }, []);

    return(
        <React.Fragment>
            <StudentsNavbar/>
            <div className="my-student-courses-main-container">

                <div className="my-student-courses-filter-container">
                    <div className="my-student-courses-tabs-container">
                        <h2 className={`my-student-courses-filter-tab ${tabSelected[0] ? "my-student-courses-filter-tab-selected" : "otherclass"}`} onClick={handleSelectTab1}>In Progress</h2>
                        <h2 className={`my-student-courses-filter-tab ${tabSelected[1] ? "my-student-courses-filter-tab-selected" : "otherclass"}`} onClick={handleSelectTab2}>Finished</h2>
                    </div>

                    <div></div>
                </div>
                
                <div className="my-student-courses-swinlane-container">
                    <h2 className="my-student-courses-swinlane-text">International certifications you are in progress:</h2>
                    <div></div>
                </div>
                
                <div className="my-student-courses-cards-wrap">
                    
                    {    
                        studentCourseList.map( (course) => ( 
                            <MyCourseCard 
                                key={course.id} 
                                name = {course.courseName} 
                                startDate = {course.startDate} 
                                endDate={course.endDate}
                                learningCourseId={course.learningCourseId}
                                courseIdentifier= {course.courseIdentifier}
                            /> 
                        ))
                    }
                </div>
            </div>
            
            
        </React.Fragment>
    );
}

export { MyStudentCourses };