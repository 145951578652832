import React, { useState } from "react";
import logo from "./../../imgs/Brand/logo-main.webp";
// import LoginPage from "../LoginPage";


import { LeftMenu } from "./../../components/AdminPanel/LeftMenu";
import { SettingsView } from "./../../components/AdminPanel/SettingsView";

import "./../../styles/dashboardPage.css";

import { useNavigate } from "react-router-dom";
import { AddButtom } from "../../components/AdminPanel/AddButtom";
import { LogoutButtom } from "../../components/AdminPanel/LogoutButtom";
import  { AddCourseForm } from "../../components/AdminPanel/AddCourseForm";

function Settings() {

  const [shouldShowOpenCoursesView, setshouldShowOpenCoursesView] =useState(false);
  const navigate = useNavigate();

  const toogleAddButtomClick = () => {
    setshouldShowOpenCoursesView(!shouldShowOpenCoursesView);
  };

  return (
    <React.Fragment>
      <div className="dashboardPage-main-container">
      
        <div className="dashboardPage-left-panel">
          <div className="dashboardPage-left-panel-header">
            <img src={logo} className="dashboardPage-logo" title="agile excellence main logo" alt="agilexcellence logo" onClick ={() => navigate("/")}/>
            <LeftMenu/>
          </div>
          <LogoutButtom/>
        </div>

        <div className="dashboardPage-right-panel">
          <AddButtom
            btn_name="New course"
            onClick={toogleAddButtomClick}
          />
          <SettingsView/>
          {shouldShowOpenCoursesView && <AddCourseForm onCloseAddCourseFormClick={toogleAddButtomClick}/>}
        
        </div>

      </div>
      
    </React.Fragment>
  );
}

export {Settings};