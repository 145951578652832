import React, {useState,useEffect} from "react";
import "./../../styles/productStepFlowGrid.css";

import { getGraphQl } from "../../hooks/ApiCalls";
import { ProductStepFlow } from "./ProductStepFlow";

function ProductStepFlowGrid ({productId}) {
    const [stepFlow, setstepFlow] = useState([]);
    
    let graphQuery = `
        query {
            GetProductStepFlow(ProductStepFlow:{productId:"${productId}"}) {
            id
            stepGuid
            name
            description
            stepOrder
            }
        }
    `;

    useEffect(() => {

        (async () => {
            const {data:{data:{GetProductStepFlow}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            setstepFlow(GetProductStepFlow);
        })();

      }, []);

    return(
        <div className="product-stepflow-grid-main-container">
            
            <table className="product-stepflow-grid-table">
                <tr>
                    <td className="product-stepflow-grid-table-colum">Step Name</td>
                    <td className="product-stepflow-grid-table-colum">Description</td>
                    <td className="product-stepflow-grid-table-colum">Actions</td>
                </tr>
                {  
                    stepFlow ? 
                    stepFlow.map( (step) => ( 
                        <ProductStepFlow 
                            key={step.id} 
                            stepGuid = {step.stepGuid}
                            stepName = {step.name}
                            description = {step.description}
                            stepOrder = {step.stepOrder}
                        /> 
                    ))
                    : ""
                }
                
            </table>
        </div>
    );
}

export {ProductStepFlowGrid};