import React, {useState,useEffect} from "react";
import "./../../styles/productStepFlowGrid.css";

import { getGraphQl } from "../../hooks/ApiCalls";
import { RouteItem } from "./RouteItem";

function RouteItemsGrid ({learningRouteId}) {
    const [routeItems, setrouteItems] = useState([]);
    
    let graphQuery = `
        query {
            GetRouteItems(RouteItem:{learningRouteId:"${learningRouteId}"}) {
            id
            name
            productId
            difficulty
            competency
            courseIdentifier
            }
        }
    `;

    useEffect(() => {

        (async () => {
            const {data:{data:{GetRouteItems}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            setrouteItems(GetRouteItems);
        })();

      }, []);

    return(
        <div className="product-stepflow-grid-main-container">
            
            <table className="product-stepflow-grid-table">
                <tr>
                    <td className="product-stepflow-grid-table-colum">Course Name</td>
                    <td className="product-stepflow-grid-table-colum">Difficulty</td>
                    <td className="product-stepflow-grid-table-colum">Competency</td>
                    <td className="product-stepflow-grid-table-colum">Actions</td>
                </tr>
                {  
                    routeItems ? 
                    routeItems.map( (item) => ( 
                        <RouteItem 
                            key={item.id} 
                            name = {item.name}
                            description = {item.description}
                            difficulty = {item.difficulty}
                            competency = {item.competency}
                        /> 
                    ))
                    : ""
                }
                
            </table>
        </div>
    );
}

export {RouteItemsGrid};