import React, {useEffect, useState} from "react";
import "../../styles/productsView.css"

import { useNavigate } from "react-router-dom";
import { ToogleContext } from "../../hooks/ToogleContext";
import { LearningRouteCard } from "./LearningRouteCard";

import { getGraphQl } from "../../hooks/ApiCalls";

function LearningRoutesView () {

    const {learningRoutesList, setlearningRoutesList} = React.useContext(ToogleContext);
    const {userLocation, setuserLocation} = React.useContext(ToogleContext);
    
    let graphQuery = `
        query {
            LearningRoute {
            id
            name
            description
            routeIdentifier
            learningRouteId
            image
            enabled
            }
        }
    `;

    useEffect(() => {

        setuserLocation([false,false,false,false,false,false, false, true,false]);
        (async () => {
            const {data:{data:{LearningRoute}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            setlearningRoutesList(LearningRoute);
        })();

      }, []);

    return (
        <div className="product-view-main-container">
            {
                learningRoutesList.map( (route) => ( 
                    <LearningRouteCard 
                        key={route.id} 
                        learningRouteIcon = {route.image}
                        LearningRouteName = {route.name}
                        LearningRouteDescription= {route.description} 
                        learningRouteId= {route.learningRouteId}
                    /> 
                ))
            }
        </div>
    );

}

export { LearningRoutesView };