import { faBullseye } from '@fortawesome/free-solid-svg-icons';
import React, { useState }  from 'react';
// import { useLocalStorage } from './useLocalStorage';

const ToogleContext = React.createContext();

function ToogleProvider(props) {

// const { languague: defaultLanguage} = useLocalStorage('APP_VARS_V1', []);

const [appLanguage, setappLanguage] = React.useState("Español");
const [token, setToken] = useState();
const [openChatBot, setopenChatBot] = React.useState(true);
const [openHamburguer, setopenHamburguer] = React.useState(false);
const [openToogleMenu, setopenToogleMenu] = React.useState(false);
const [openQuoteModal, setopenQuoteModal] = React.useState(false);
const [inscriptionsList, setinscriptionsList] = React.useState([]);
const [usersList, setusersList] = React.useState([]);
const [productList, setproductList] = React.useState([]);
const [voucherList, setvoucherList] = React.useState([]);
const [inscriptionEnabledToogle, setinscriptionEnabledToogle] = React.useState(true);
const [scrollTop, setscrollTop] = React.useState(false);
const [userLocation, setuserLocation] = React.useState([false,false,false,false,false,false,false,false,false]);
const [openInscriptionDetails, setopenInscriptionDetails] = React.useState(false);
const [openUserDetails, setopenUserDetails] = React.useState(false);
const [studentCourseList, setstudentCourseList] = React.useState([]);
const [trainerCourseList, settrainerCourseList] = React.useState([]);
const [courseList, setcourseList] = React.useState([]);
const [learningRoutesList, setlearningRoutesList] = React.useState([]);


return (
    <ToogleContext.Provider value={{
      token,
      setToken,
      appLanguage,
      setappLanguage,
      openChatBot,
      setopenChatBot,
      openHamburguer,
      setopenHamburguer,
      openToogleMenu,
      setopenToogleMenu,
      openQuoteModal,
      setopenQuoteModal,
      scrollTop,
      setscrollTop,
      trainerCourseList,
      settrainerCourseList,
      studentCourseList,
      setstudentCourseList,
      inscriptionsList,
      setinscriptionsList,
      courseList,
      setcourseList,
      productList,
      setproductList,
      usersList,
      setusersList,
      voucherList,
      setvoucherList,
      inscriptionEnabledToogle,
      setinscriptionEnabledToogle,
      userLocation,
      setuserLocation,
      openInscriptionDetails,
      setopenInscriptionDetails,
      openUserDetails,
      setopenUserDetails,
      learningRoutesList,
      setlearningRoutesList,
    }}>
      {props.children}
    </ToogleContext.Provider>
  );

  }

export { ToogleContext, ToogleProvider };