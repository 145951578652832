import React, {useState,useEffect} from "react";
import "./../../styles/courseContentAcordion.css";

import { getGraphQl } from "../../hooks/ApiCalls";
import { CourseContentAcordionItem } from "./CourseContentAcordionItem";

import bookIcon from "../../imgs/StudentsIcons/book.svg";
import youtubeIcon from "../../imgs/StudentsIcons/youtubeIcon.svg";
import uncheckIcon from "../../imgs/radio_button_unchecked_FILL0_wght400_GRAD0_opsz48.svg";
import checkedIcon from "../../imgs/StudentsIcons/check-circle.svg";

function CourseContentAcordion ({moduleName,learningCourseModuleId, moduleOrder, moduleProgress}) {

    let accordionState = false;
    moduleOrder == 1 && moduleProgress !=100 ? accordionState= true : accordionState= false;

    const [acordionSwinlaneOpen, setacordionSwinlaneOpen] = useState(accordionState);
    const [LearningCourseModuleItems, setLearningCourseModuleItems] = useState([]);

    const handleOpenAcordionSwinlane = () => {
        setacordionSwinlaneOpen(!acordionSwinlaneOpen);
    };

    let graphQuery = `
        query
        {
            GetLearningCourseModuleItems (LearningCourseModuleItems:{learningCourseModuleId:"${learningCourseModuleId}"}) {
                name
                moduleItemOrder
                isCompleted
                learningCourseModuleId
                contentURL
                contentType
                learningCourseModuleItemId
            }
        }
    `;

    useEffect(() => {

        (async () => {
            const {data:{data:{GetLearningCourseModuleItems}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            setLearningCourseModuleItems(GetLearningCourseModuleItems);
        })();

      }, []);

    return (
        <div className={`course-content-acordion-main-container ${acordionSwinlaneOpen ? "course-content-acordion-main-container-open" : "otherclass"}`}>
            <div className={`course-content-acordion-inner-container ${acordionSwinlaneOpen ? "course-content-acordion-inner-container-open" : "otherclass2"}`}  onClick={handleOpenAcordionSwinlane}>
                <h1  className="course-content-acordion-module-name">{moduleName}</h1>
                <h2 className="course-content-acordion-module-progress">{moduleProgress}%</h2>
            </div>

            {
                acordionSwinlaneOpen ?
                    <div className="course-content-acordion-module-content-main-container">
                        <div className="course-content-acordion-module-content-inner-container">

                            {    
                                LearningCourseModuleItems.map( (courseModuleItem) => ( 
                                    <CourseContentAcordionItem
                                        key = {courseModuleItem.id} 
                                        name = {courseModuleItem.name}
                                        isCompleted= {courseModuleItem.isCompleted}
                                        moduleItemOrder = {courseModuleItem.moduleItemOrder}
                                        contentURL = {courseModuleItem.contentURL}
                                        contentType = {courseModuleItem.contentType}
                                    /> 
                                ))
                            }
                            
                        </div>

                    </div>
                :
                    ""
            }
            
        </div>
    )

}

export {CourseContentAcordion};