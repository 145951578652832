import react, { useState, useEffect } from "react";
import "./../../styles/courseApp.css";

import backArrow from "../../imgs/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg";
import forwarkArrow from "../../imgs/arrow_forward_ios_FILL0_wght400_GRAD0_opsz48.svg";

import { StudentsNavbar } from "../../components/Students/StudentsNavbar";
import { ModuleContent } from "./../../components/Students/CourseApp/ModuleContent.js";

function CourseApp () {

    const [moduleContentList, setmoduleContentList] = useState([]);
    const [openContentList, setopenContentList] = useState(true);

    const handleOpenContentList = () => {
        setopenContentList(!openContentList);
    };

    useEffect(() => {

        (async () => {
            setmoduleContentList([{id:1, type:"lecture", name:"Programa PSM1", checked:true}, {id:2, type:"video", name:"Bienvenida", checked:false}, {id:3, type:"quiz", name:"Auto evaluación", checked:false}])
            // const {data:{data:{GetMyStudentCourses}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            // setstudentCourseList(GetMyStudentCourses);
            //setmoduleContentList([{id:1, type:"lecture", name:"Programa PSM1", checked:true}, {id:2, type:"video", name:"Bienvenida", checked:false}, {id:3, type:"quiz", name:"Auto evaluación", checked:false}])

        })();

      }, []);

    return(
        <react.Fragment>
            <StudentsNavbar/>
            <div className="course-app-main-container">
                <div className="course-app-inner-container">

                    <div className="course-app-left-container">
                        <div className="course-app-left-container-header">
                            <button className="course-app-left-container-header-btn"><img src={backArrow} className="course-app-btn-icon"/>Anterior</button>
                            <div className="course-app-left-container-module-info-container">
                                <h2 className="course-app-left-container-module-name">Modulo 1- Bienvenida</h2>
                                <h1 className="course-app-left-container-content-name">Bienvenida</h1>
                            </div>
                            <button className="course-app-left-container-header-btn"><img src={forwarkArrow}/>Siguiente</button>
                        </div>

                        <div className="course-app-left-container-video-container">
                            <iframe width="100%" height="500" src="https://iframe.mediadelivery.net/embed/246380/24a0d0a0-f22b-4570-81ff-64547ed833ba?autoplay=false&loop=false&muted=false&preload=true&responsive=true" loading="lazy"  allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowfullscreen="true"></iframe>
                            {/* <iframe width="100%" height="500" src={`http://www.youtube.com/embed?listType=playlist&list=PLEV6bfKfIrxnuL40Dvn0MlbJDcR9GJbpM&rel=0&modestbranding=0&showinfo=0`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; " allowfullscreen></iframe> */}
                        </div>
                    </div>

                    <div className="course-app-right-container">
                        <div className="course-app-right-inner-container">
                            <div className="course-app-right-container-header">
                                <h1 className="course-app-right-container-course-name">Professional Scrum Master</h1>
                            </div>

                            <div className="course-app-right-container-module-info-container">
                                <div className="course-app-right-container-progress-container">
                                    <h1 className="course-app-right-container-progress-numer">1</h1>
                                </div>
                                <div className="course-app-right-container-module-progress-container">
                                    <h1 className="course-app-right-container-current-module-name">Presentación</h1>
                                    <h3 className="course-app-right-container-current-module-progress">1 Clase</h3>
                                </div>
                                <img src={forwarkArrow}/>
                            </div>

                            <div className="course-app-right-container-content-info-container">
                                <div className="course-app-right-container-content-container">

                                    <div className="course-app-right-container-content-header" onClick={handleOpenContentList}>
                                        <h1 className="course-app-right-container-header-title">Bienvenida</h1>
                                        <div className="course-app-right-container-header-right-container">
                                            <h2 className="course-app-right-container-header-porcentage-number">30%</h2>
                                            <img src={forwarkArrow} className={`course-app-right-container-header-icon ${openContentList ? "course-app-right-container-header-icon-closed" : "otherclass"}`} alt="row icon for indicate acordion is open or closed"/>
                                        </div>
                                    </div>

                                    {   
                                        openContentList ? 
                                            moduleContentList.map((content, index) => (
                                                <ModuleContent
                                                    key={index}
                                                    name={content.name}
                                                />
                                            ))
                                        :
                                            ""
                                    }
                                    
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </react.Fragment>
    );
}

export {CourseApp};