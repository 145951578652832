import React, {useState}  from "react";

import avatar from "./../../imgs/jesus.jpeg";
import logo from "./../../imgs/Brand/logo-main.webp";

import "../../styles/trainerNavbar.css";

import { useNavigate } from "react-router-dom";
import { ToogleContext } from "../../hooks/ToogleContext";

function TrainerNavbar () {
    const navigate = useNavigate();
    const {openHamburguer, setopenHamburguer} = React.useContext(ToogleContext);

    const openHamburger = () => {
        setopenHamburguer(!openHamburguer);
    };

    return(
        <header>
            <div className="trainer-nav-main-container">
                <div className="trainer-nav-left">
                    <button className={`hamb-menu-trainer ${openHamburguer && "opened"} `} onClick={openHamburger}>  
                        <svg width="100" height="100" viewBox="0 0 100 100">
                            <path class="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
                            <path class="line line2" d="M 20,50 H 80" />
                            <path class="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
                        </svg> 
                    </button>
                    <img src= {logo} className= "trainer-nav-left-logo" alt="trainer pisoco academy logo" onClick ={() => navigate("/trainer")}/>

                    <div className="trainer-nav-search">
                        <form>
                            <input type="text" className="trainer-nav-search-input" placeholder="search"/>
                        </form>
                    </div>

                </div>

                <div className="trainer-nav-right">
                    <nav className="trainer-nav-right-navigators">
                        <li className="trainer-nav-navigator" onClick ={() => navigate("/trainer/my-courses")}><h4>All Courses</h4></li>
                        <li className="trainer-nav-navigator" onClick ={() => navigate("/trainer/my-courses")}><h4>My Courses</h4></li>
                        <li className="trainer-nav-navigator"><h4>My Certificates</h4> </li>
                    </nav>
                    
                    <img src= {avatar} className="trainer-logged-user-image" alt="trainer-logged-user-image" onClick ={() => navigate("/")}/>
                </div>
                

            </div>
        </header>
    )

}

export {TrainerNavbar};