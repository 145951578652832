import React, {useState} from "react";
import "./../../styles/courseInscription.css";

import viewIcon from "./../../imgs/ActionsIcons/view.svg";
import checkIcon from "./../../imgs/ActionsIcons/confirm.svg";
import cancelIcon from "./../../imgs/ActionsIcons/cancel.svg";

import { InscriptionDetailsModal } from "./InscriptionDetailsModal";
import {getGraphQl} from "../../hooks/ApiCalls";

function CourseInscription ({courseInscriptionDate,courseName,InscriptionConfirmed,paymentReceived,InscriptionEnabled,inscriptionGuid, onCloseInscriptionDetails}) {

    console.log(InscriptionConfirmed)
    const [shouldOpenActionMenu, setshouldOpenActionMenu] = React.useState(false);
    const [openPaymentDetails, setopenPaymentDetails] = React.useState(false);

    const handleInscriptionActions = () => {
        setshouldOpenActionMenu(!shouldOpenActionMenu);
    };

    const handleCloseInscriptionDetails = () => {
        setopenPaymentDetails(false);
    };

    const handleViewDetails = () => {
        setshouldOpenActionMenu(false);
        setopenPaymentDetails(true);
    };

    return(
        <React.Fragment>
            <tr className="course-inscription-entry-table-line">
                <td className="course-inscription-entry-table-item">{courseInscriptionDate}</td>
                <td className="course-inscription-entry-table-item">{courseName}</td>
                <td className="course-inscription-entry-table-item"><img  className="course-inscription-entry-action-icon"src ={InscriptionConfirmed == true ? checkIcon : cancelIcon} /></td>
                <td className="course-inscription-entry-table-item"><img className="course-inscription-entry-action-icon" src={paymentReceived == true ? checkIcon : cancelIcon }/></td>
                <td className="course-inscription-entry-table-item"><img className="course-inscription-entry-action-icon" src={InscriptionEnabled == true ? checkIcon : cancelIcon}/></td>
                <td className="course-inscription-entry-table-actions-item" onClick={handleInscriptionActions}>Actions</td>
            </tr> 

            <div className= {`course-inscription-entry-action-menu ${shouldOpenActionMenu && "course-inscription-entry-action-menu-open" } `}>
                    <div className="course-inscription-entry-action-items-container">
                        <div className="course-inscription-entry-action-item-container">
                            <li className="course-inscription-entry-action-item" onClick={handleViewDetails}>View Details</li>
                            <img src={viewIcon} className="course-inscription-entry-action-icon"/>
                        </div>
                    </div>
            </div>

            {openPaymentDetails &&  <InscriptionDetailsModal inscriptionGuid= {inscriptionGuid} onCloseInscriptionDetails={handleCloseInscriptionDetails} />}
        </React.Fragment>
        
    );
}

export {CourseInscription};