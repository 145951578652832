import React, {useEffect, useState} from "react";
import "../../styles/openCoursesView.css"

import courseIcon from "../../imgs/github.svg";
import { ToogleContext } from "./../../hooks/ToogleContext";
import { OpenCoursesCard } from "./OpenCoursesCard";

import { getGraphQl } from "./../../hooks/ApiCalls";

function OpenCoursesView () {

    const {courseList, setcourseList} = React.useContext(ToogleContext);
    const {userLocation, setuserLocation} = React.useContext(ToogleContext);
    
    let graphQuery = `
        query{
            GetLearningCoursesOpen {
            id
            courseIdentifier
            name
            productId
            learningCourseId
            startDate
            endDate
            enabled
            inscriptionEnabled
            } 
        }
    `;

    useEffect(() => {
        setuserLocation([true,false,false,false,false,false,false]);
        
        (async () => {
            const {data:{data:{GetLearningCoursesOpen}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            setcourseList(GetLearningCoursesOpen);
        })();

      }, []);

    return (
        <div className="open-courses-view-main-container">
            {
                courseList?.map( (course) => ( 
                    <OpenCoursesCard 
                        key={course.id} 
                        courseIcon={courseIcon}
                        courseIdentifier= {course.courseIdentifier}
                        courseName= {course.name} 
                        learningCourseId= {course.learningCourseId}
                        inscriptionEnable={course.inscriptionEnabled}
                    /> 
                ))
            }
        </div>
    );

}

export { OpenCoursesView };