import "./../../styles/myCourseCard.css";
import PSMI from "./../../imgs/CourseIcons/SMCaratula.png";
import PSM2 from "./../../imgs/CourseIcons/PSM2Caratula.webp";
import KSD from "./../../imgs/CourseIcons/KSDCaratula.png";
import KSI from "./../../imgs/CourseIcons/KSICaratula.png";
import JIRAF from "./../../imgs/CourseIcons/JiraCaratula.png";
import PMI from "./../../imgs/CourseIcons/PMICaratula.png";
import GDF from "./../../imgs/CourseIcons/GDFCaratula.png";

import { useNavigate } from "react-router-dom";

export const MyCourseCard = ({name, startDate, endDate, learningCourseId,courseIdentifier}) => {

    const navigate = useNavigate();

    return (
        <div className="my-course-card-container">
            <div className="my-course-card-img-block">
                <img src= {courseIdentifier == "PSMI"? PSMI : courseIdentifier == "KSD" ? KSD : courseIdentifier == "KSI" ? KSI : courseIdentifier == "JIRAF" ? JIRAF : courseIdentifier == "PMI" ? PMI : courseIdentifier == "GDF" ? GDF : courseIdentifier == "PSM2" ? PSM2 : "" } className="my-course-card-img" alt="course presentation"/>
            </div>
            <h1 className="my-course-card-title"> {name} </h1>
            <h2 className="my-course-card-date"> {startDate} - {endDate}</h2>
            <button className="my-course-card-btn" onClick={() => navigate(`/my-courses/course-students/${learningCourseId}`)} >Ingresar</button>
        </div>
    );
};
