import React, {useEffect,useState} from "react";

import {getGraphQl} from "../../hooks/ApiCalls";

function SelectAvailableCourses ({defaultValue, onChange,name}) {

    const [selectValues, setselectValues] = useState([]);

    let graphQuery = `
        query {
            GetLearningCoursesOpen {
                learningCourseId
                name
                inscriptionEnabled
            }
        }
    `;

    useEffect(() => {
        (async () => {
            const {data:{data:{GetLearningCoursesOpen}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            setselectValues(GetLearningCoursesOpen.filter((course)=> course.inscriptionEnabled));
        })();

      }, []);

    return (
        <React.Fragment>
                <select onChange={onChange} name={name}>
                    {selectValues.map(({learningCourseId,name}) => {
                        return <option selected={learningCourseId === defaultValue} value={learningCourseId}>{name}</option>
                    })}
                </select>
        </React.Fragment>
    );

}

export {SelectAvailableCourses};