import React, {useState} from "react";
import "./../../styles/paymentEntry.css";

import viewIcon from "./../../imgs/ActionsIcons/view.svg";
import moneyIcon from "./../../imgs/ActionsIcons/money.svg";
import paypalIcon from "./../../imgs/ActionsIcons/paypal.svg";
import confirmIcon from "./../../imgs/ActionsIcons/confirm.svg";
import cancelIcon from "./../../imgs/ActionsIcons/cancel.svg";

import {getGraphQl} from "./../../hooks/ApiCalls";

function PaymentEntry ({paymentDate,name,paymentAmount,balance,paymentGuid}) {

    const [shouldOpenActionMenu, setshouldOpenActionMenu] = React.useState(false);
    const [openPaymentDetails, setopenPaymentDetails] = React.useState(false);

    const handlePaymentActions = () => {
        setshouldOpenActionMenu(!shouldOpenActionMenu);
    };

    const handleClosePaymentDetails = () => {
        setopenPaymentDetails(false);
    };

    const handleViewDetails = () => {
        setshouldOpenActionMenu(false);
        setopenPaymentDetails(true);
    };

    const handleDeletePayment = async () => {
        let graphQuery = `
                mutation {
                    deletePayment(Payment:{paymentGuid:"${paymentGuid}"}) {
                    message
                    }
                }
        `;
    
       let status = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
       if (status){window.alert("Payment Deleted")}
       window.location.reload();
    };

    return(
        <React.Fragment>
            <tr className="payment-entry-table-line">
                <td className="payment-entry-table-item">{paymentDate}</td>
                <td className="payment-entry-table-item">{name}</td>
                <td className="payment-entry-table-item">{paymentAmount}</td>
                <td className="payment-entry-table-item">{balance}</td>
                <td className="payment-entry-table-actions-item" onClick={handlePaymentActions}>Actions</td>
            </tr> 

            <div className= {`payment-entry-action-menu ${shouldOpenActionMenu && "payment-entry-action-menu-open" } `}>
                    <div className="payment-entry-action-items-container">
                        <div className="payment-entry-action-item-container">
                            <li className="payment-entry-action-item" onClick={handleViewDetails}>View Details</li>
                            <img src={viewIcon} className="payment-entry-action-icon"/>
                        </div>
                        
                        <div className="payment-entry-action-item-container">
                            <li className="payment-entry-action-item" onClick={handleDeletePayment}>Delete Payment</li>
                            <img src={cancelIcon} className="payment-entry-action-icon"/>
                        </div>

                    </div>
            </div>

            {/* {openPaymentDetails &&  <PaymentDetailsModal paymentGuid= {paymentGuid} onClosePaymentDetails={handleClosePaymentDetails} />} */}
        </React.Fragment>
        
    );
}

export {PaymentEntry}