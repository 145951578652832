import React,{useEffect,useCallback} from "react";
import  {createPortal}  from "react-dom";
import "../../styles/AddCourseForm.css";

import {getGraphQl} from "../../hooks/ApiCalls";

function AddPaymentForm ({onCloseAddPaymentFormClick,inscriptionGuid}) {

    const [values, setValues] = React.useState({
        name:"",
        paymentAmount: "",
        paymentDate: "",
        chargeAmount: "",
      });

      const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
          onCloseAddPaymentFormClick();
        }
      }, []);
    
      useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
      }, [escFunction]);
    
      let graphQuery= `
          mutation {
            createPayment(Payment:{inscriptionGuid:"${inscriptionGuid}",name:"${values.name}",paymentAmount:"${values.paymentAmount}",chargeAmount:"${values.chargeAmount}",paymentDate:"${values.paymentDate}"}) {
              message
            }
          }
        `;
    
      function handleChange(evt) {
        const { target } = evt;
        const { name, value } = target;
    
        const newValues = {
          ...values,
          [name]: value,
        };
        setValues(newValues);
      }
      
      const handleSubmit = async (evt) => {
        // evt.preventDefault();
        await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery})
      }

    return createPortal(
          <div className="ModalBackground">
              <div className="add-course-form-main-container" onSubmit={handleSubmit}>
                  <div className="add-course-form-container">
                      <h1 className="add-course-form-title">Add new Payment</h1>
                      <form className="add-course-form-inner-container">

                          <div className="add-course-form-items">
                              <input className="add-course-form-item" name= "name" type="text" placeholder="name" value={values.name} onChange={handleChange} required />
                              <input className="add-course-form-item" name= "paymentAmount" type="number" placeholder="paymentAmount" value={values.paymentAmount} onChange={handleChange} required />
                              <input className="add-course-form-item" name= "paymentDate" type="date" placeholder="paymentDate" value={values.paymentDate} onChange={handleChange} required />
                              <input className="add-course-form-item" name= "chargeAmount" type="number" placeholder="chargeAmount" value={values.chargeAmount} onChange={handleChange} required/>
                          </div>
                          
                          <div className="add-course-btns">
                              <button className="add-course-btn-create" type="submit"> Add Payment </button>
                              <button className="add-course-btn-cancel" onClick={onCloseAddPaymentFormClick}> Cancel </button>
                          </div>
                      </form>
                  </div>
              </div>
            </div>
            ,
            document.getElementById("modal")
    );
}

export {AddPaymentForm};