import React, {useEffect,useCallback,useState} from "react";
import  {createPortal}  from "react-dom";
import "./../../styles/userDetailModal.css";

import closeIcon from "./../../imgs/ActionsIcons/close-circle.svg";
import addBtn from "./../../imgs/ActionsIcons/money.svg";

import { ToogleContext } from "../../hooks/ToogleContext";
import { getGraphQl } from "../../hooks/ApiCalls";
import { isTheSameObject, getNewObject,buildMutationBody } from "../../utils/utils";
import { UserCoursesGrid } from "./UserCoursesGrid";

function UserDetailModal ({dni, onCloseUserDetails}) {

    const [inscriptionDetailModal, setInscriptionDetailModal] = React.useState("");
    const [inscriptionDetailInitialData,setinscriptionDetailInitialData] = React.useState("");
    const [isDetailsUpdated, setisDetailsUpdated] = React.useState(false);
    const [shouldShowAddPayment, setshouldShowAddPayment] =useState(false);

    function handleInscriptionDetailChange(evt) {
        const { target } = evt;
        const { name, value } = target;
    
        const newValues = {
          ...inscriptionDetailModal,
          [name]: value,
        };

        setInscriptionDetailModal(newValues);
        
        if (!isTheSameObject(newValues,inscriptionDetailInitialData)){
            setisDetailsUpdated(true);
        } else {
            setisDetailsUpdated(false);
        }

    }

    const handleSaveChanges = async (evt) => {
        evt.preventDefault();

        let auxObj = inscriptionDetailModal;
        delete auxObj.password;
        delete auxObj.courseInscriptionDate;

        // const auxObjStr = JSON.stringify(auxObj);
        let SaveChangesQuery = `
            mutation{
                updateStudentInscription (StudentInscription:
                    ${buildMutationBody(auxObj)}
                ) {
                    message
                    }
            }
        `;

        let status = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: SaveChangesQuery});
        if (status){window.alert("Changes Saved")}
        window.location.reload();
    };

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            onCloseUserDetails();
        }
      }, []);
    
      useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
      }, [escFunction]);

    let graphQuery = `
        query{
            GetUsersbyDni(Users:{dni:"${dni}"}) {
            id
            dni
            name
            lastname
            nickname
            company
            email
            password
            phone
            address
            enabled
            }
        }
    `;

    useEffect(() => {
        (async () => {
            const {data:{data:{GetUsersbyDni}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            
            setInscriptionDetailModal(getNewObject(GetUsersbyDni));
            setinscriptionDetailInitialData(getNewObject(GetUsersbyDni));
        })();

    }, []);

    return createPortal(
        <div className="ModalBackground">
            <div className="user-details-modal-main-container">
                <div className="user-details-modal-container">
                    <img src={closeIcon} className="user-detail-modal-close-btn" alt="close modal icon" onClick={onCloseUserDetails}/>   
                    <form className="user-detail-modal-form">
                        
                        <div className="user-detail-form-student-info">
                            <h2 className="user-detail-form-title"> User Information</h2>
                            <div className="user-detail-student-info-block">
                                <div className="user-detail-student-info-line">
                                    <div className="user-detail-modal-item">
                                        <label for="name" className="user-detail-modal-item-label">Name</label>
                                        <input className="user-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.name} name="name" />
                                    </div>
                                    <div className="user-detail-modal-item">
                                        <label for="lastname" className="user-detail-modal-item-label">Last Name</label>
                                        <input className="user-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.lastname} name="lastname"/>
                                    </div>
                                    <div className="user-detail-modal-item">
                                        <label for="dni" className="user-detail-modal-item-label">DNI</label>
                                        <input className="user-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.dni} name="dni"/>
                                    </div>
                                </div>
                                <div className="user-detail-student-info-line">
                                    <div className="user-detail-modal-item">
                                        <label for="email" className="user-detail-modal-item-label">email</label>
                                        <input className="user-detail-modal-item-value"  onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.email} name="email"/>
                                    </div>
                                    <div className="user-detail-modal-item">
                                        <label for="phone" className="user-detail-modal-item-label">Phone</label>
                                        <input className="user-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.phone} name="phone"/>
                                    </div>
                                    <div className="user-detail-modal-item">
                                        <label for="address" className="user-detail-modal-item-label">Address</label>
                                        <input className="user-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.address} name="address"/>
                                    </div>
                                </div>
                                <div className="user-detail-student-info-line">
                                    <div className="user-detail-modal-item">
                                        <label for="city" className="user-detail-modal-item-label">City</label>
                                        <input className="user-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.city} name="city"/>
                                    </div>
                                    <div className="user-detail-modal-item">
                                        <label for="country" className="user-detail-modal-item-label">Country</label>
                                        <input className="user-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.country} name="country"/>
                                    </div>
                                    <div className="user-detail-modal-item">
                                        <label for="company" className="user-detail-modal-item-label">Company</label>
                                        <input className="user-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.company} name="company" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="user-detail-form-financial-info">
                            <h2 className="user-detail-form-title"> Inscription Information</h2>
                            <div className="user-detail-financial-info-block">
                                <div className="inscription-detail-modal-payment-grid">
                                    <UserCoursesGrid
                                        dni = {dni}
                                    />
                                </div>
                            </div>
                        </div>
                        
                        <div className="user-detail-form-btns">
                        {isDetailsUpdated ? <button className="user-detail-btn-save" onClick={handleSaveChanges}>Save Changes</button> : ""}
                            <button className="user-detail-btn-cancel" onClick={onCloseUserDetails}>Cancel</button>
                        </div>
                        
                    </form>
                </div>
            </div>
        </div>
        ,
        document.getElementById("modal")
    );
}

export {UserDetailModal};