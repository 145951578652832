import React, { useState } from "react";
import "./../../styles/MyOpenInscriptionCoursesCard.css";

import { useNavigate } from "react-router-dom";
import { getGraphQl } from "../../hooks/ApiCalls";

import {Spinner} from "./../Spinner";

export const MyOpenInscriptionCoursesCard = ({name, startDate, endDate, learningCourseId, courseIdentifier, token}) => {

    const navigate = useNavigate();
    const [loading,setloading] = useState(false);

    let graphQuery = `
        mutation {
            createMyOpenInscriptionCourses(MyOpenInscription:{token:"${token}", learningCourseId: "${learningCourseId}"}) {
            token
            }
        }
    `;

    const handleConfirmInscription = async () => {
        setloading(true);
        const isInscribed = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
        if (isInscribed) { setloading(false)}
        window.location.reload();
    };

    return (
        <div className="my-open-inscription-card-container">
            <h1 className="my-open-inscription-card-title"> {name} </h1>
            <h2 className="my-open-inscription-card-date"> {startDate} - {endDate}</h2>
            <button className="my-open-inscription-card-btn" onClick={handleConfirmInscription}>{loading ? <Spinner/>: "Inscribirme"}</button>
        </div>
    );
};
