import React, {useEffect,useCallback,useState} from "react";
import  {createPortal}  from "react-dom";
import "./../../styles/inscriptionDetailsModal.css";

import closeIcon from "./../../imgs/ActionsIcons/close-circle.svg";
import addBtn from "./../../imgs/ActionsIcons/money.svg";

import { ToogleContext } from "../../hooks/ToogleContext";
import { getGraphQl } from "../../hooks/ApiCalls";
import { isTheSameObject, getNewObject,buildMutationBody } from "../../utils/utils";
import { PaymentsGrid } from "./PaymentsGrid";
import { AddPaymentForm } from "./AddPaymentForm";
import { SelectAvailableCourses } from "../Selector/SelectAvailableCourses";

function InscriptionDetailsModal ({inscriptionGuid, onCloseInscriptionDetails}) {

    const [inscriptionDetailModal, setInscriptionDetailModal] = React.useState("");
    const [inscriptionDetailInitialData,setinscriptionDetailInitialData] = React.useState("");
    const [isDetailsUpdated, setisDetailsUpdated] = React.useState(false);
    const [shouldShowAddPayment, setshouldShowAddPayment] =useState(false);

    function handleInscriptionDetailChange(evt) {
        const { target } = evt;
        const { name, value } = target;
    
        const newValues = {
          ...inscriptionDetailModal,
          [name]: value,
        };

        setInscriptionDetailModal(newValues);
        
        if (!isTheSameObject(newValues,inscriptionDetailInitialData)){
            setisDetailsUpdated(true);
        } else {
            setisDetailsUpdated(false);
        }

    }

    const handleAddPayment = () => {
        setshouldShowAddPayment(!shouldShowAddPayment);
      };

    const handleSaveChanges = async (evt) => {
        evt.preventDefault();

        let auxObj = inscriptionDetailModal;
        delete auxObj.password;
        delete auxObj.courseInscriptionDate;

        // const auxObjStr = JSON.stringify(auxObj);
        console.log(auxObj);
        let SaveChangesQuery = `
            mutation{
                updateStudentInscription (StudentInscription:
                    ${buildMutationBody(auxObj)}
                ) {
                    message
                    }
            }
        `;

        let status = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: SaveChangesQuery});
        if (status){window.alert("Changes Saved")}
        window.location.reload();
    };

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            onCloseInscriptionDetails();
        }
      }, []);
    
      useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
      }, [escFunction]);

    let graphQuery = `
        query{
            GetInscriptionDetails(InscriptionDetails:{inscriptionGuid:"${inscriptionGuid}"}) {
            dni
            name
            lastname
            nickname
            email
            phone
            password
            address
            city
            country
            company
            learningCourseId
            courseInscriptionDate
            paymentReceived
            paymentMailSent
            emailPaymentAttemps
            InscriptionEnabled
            InscriptionConfirmed
            inscriptionGuid
            lastNotificationDate
            }
        }
    `;

    useEffect(() => {
        (async () => {
            const {data:{data:{GetInscriptionDetails}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            
            setInscriptionDetailModal(getNewObject(GetInscriptionDetails));
            setinscriptionDetailInitialData(getNewObject(GetInscriptionDetails));
        })();

    }, []);

    return createPortal(
        <div className="ModalBackground">
            <div className="inscription-details-modal-main-container">
                <div className="inscription-details-modal-container">
                    <img src={closeIcon} className="inscription-detail-modal-close-btn" alt="close modal icon" onClick={onCloseInscriptionDetails}/>   
                    <form className="inscription-detail-modal-form">
                        
                        <div className="inscription-detail-form-student-info">
                            <h2 className="inscription-detail-form-title"> Student Information</h2>
                            <div className="inscription-detail-student-info-block">
                                <div className="inscription-detail-student-info-line">
                                    <div className="inscription-detail-modal-item">
                                        <label for="name" className="inscription-detail-modal-item-label">Name</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.name} name="name" />
                                    </div>
                                    <div className="inscription-detail-modal-item">
                                        <label for="lastname" className="inscription-detail-modal-item-label">Last Name</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.lastname} name="lastname"/>
                                    </div>
                                    <div className="inscription-detail-modal-item">
                                        <label for="dni" className="inscription-detail-modal-item-label">DNI</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.dni} name="dni"/>
                                    </div>
                                </div>
                                <div className="inscription-detail-student-info-line">
                                    <div className="inscription-detail-modal-item">
                                        <label for="email" className="inscription-detail-modal-item-label">email</label>
                                        <input className="inscription-detail-modal-item-value"  onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.email} name="email"/>
                                    </div>
                                    <div className="inscription-detail-modal-item">
                                        <label for="phone" className="inscription-detail-modal-item-label">Phone</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.phone} name="phone"/>
                                    </div>
                                    <div className="inscription-detail-modal-item">
                                        <label for="address" className="inscription-detail-modal-item-label">Address</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.address} name="address"/>
                                    </div>
                                </div>
                                <div className="inscription-detail-student-info-line">
                                    <div className="inscription-detail-modal-item">
                                        <label for="city" className="inscription-detail-modal-item-label">City</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.city} name="city"/>
                                    </div>
                                    <div className="inscription-detail-modal-item">
                                        <label for="country" className="inscription-detail-modal-item-label">Country</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.country} name="country"/>
                                    </div>
                                    <div className="inscription-detail-modal-item">
                                        <label for="company" className="inscription-detail-modal-item-label">Company</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.company} name="company" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="inscription-detail-form-inscription-info">
                            <h2 className="inscription-detail-form-title"> Inscription Information</h2>
                            <div className="inscription-detail-inscription-info-block">
                                <div className="inscription-detail-inscription-info-line">
                                    <div className="inscription-detail-modal-item">
                                        <label for="learningCourseId" className="inscription-detail-modal-item-label">Course Name</label>
                                        {/* <input className="inscription-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.learningCourseId} name="learningCourseId"/> */}
                                        <SelectAvailableCourses
                                            defaultValue={inscriptionDetailModal.learningCourseId}
                                            onChange={handleInscriptionDetailChange}
                                            name="learningCourseId"
                                        />
                                    </div>
                                    <div className="inscription-detail-modal-item">
                                        <label for="incriptionDate" className="inscription-detail-modal-item-label">Inscription Date</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.courseInscriptionDate} name="incriptionDate"/>
                                    </div>
                                    
                                </div>
                                <div className="inscription-detail-inscription-info-line">
                                    <div className="inscription-detail-modal-item">
                                        <label for="paymentMailSent" className="inscription-detail-modal-item-label">Payment Mail Sent</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.paymentMailSent} name="paymentMailSent"/>
                                    </div>
                                    <div className="inscription-detail-modal-item">
                                        <label for="emailPaymentAttemps" className="inscription-detail-modal-item-label">Email Payment Attemps</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.emailPaymentAttemps} name="emailPaymentAttemps"/>
                                    </div>
                                </div>
                                <div className="inscription-detail-inscription-info-line">
                                    <div className="inscription-detail-modal-item">
                                        <label for="inscriptionGuid" className="inscription-detail-modal-item-label">inscription Guid</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value={inscriptionDetailModal.inscriptionGuid} name="inscriptionGuid"/>
                                    </div>
                                    <div className="inscription-detail-modal-item">
                                        <label for="lastNotificationDate" className="inscription-detail-modal-item-label">Last Notification Date</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value={inscriptionDetailModal.lastNotificationDate} name="lastNotificationDate"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="inscription-detail-form-financial-info">
                            <h2 className="inscription-detail-form-title"> Financial Information</h2>
                            <div className="inscription-detail-financial-info-block">
                                <div className="inscription-detail-financial-info-line">
                                    <div className="inscription-detail-modal-item">
                                        <label for="paymentStatus" className="inscription-detail-modal-item-label">Payment Status</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {inscriptionDetailModal.paymentReceived} name="paymentStatus"/>
                                    </div>
                                    <div className="inscription-detail-modal-item">
                                        <label for="InscriptionConfirmed" className="inscription-detail-modal-item-label">Inscription Confirmed</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value={inscriptionDetailModal.InscriptionConfirmed} name="InscriptionConfirmed"/>
                                    </div>
                                    <div className="inscription-detail-modal-item">
                                        <label for="InscriptionEnabled" className="inscription-detail-modal-item-label">Inscription Enabled
                                        <input className="inscription-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="checkbox" value={inscriptionDetailModal.InscriptionEnabled} name="InscriptionEnabled"/>
                                        </label>
                                    </div>
                                    

                                </div>
                                <div className="inscription-detail-modal-add-payment" onClick={handleAddPayment}>
                                    <h1 className="inscription-detail-modal-add-apyment-text">Add</h1>
                                    <img src={addBtn} className="inscription-detail-modal-add-payment-btn"/>
                                </div>
                                <div className="inscription-detail-modal-payment-grid">
                                    <PaymentsGrid
                                        inscriptionGuid = {inscriptionGuid}
                                    />
                                </div>
                                {
                                    shouldShowAddPayment && <AddPaymentForm 
                                        inscriptionGuid= {inscriptionGuid}
                                        onCloseAddPaymentFormClick={handleAddPayment}
                                    />
                                }
                            </div>
                        </div>
                        
                        <div className="inscription-detail-form-btns">
                        {isDetailsUpdated ? <button className="inscription-detail-btn-save" onClick={handleSaveChanges}>Save Changes</button> : ""}
                            <button className="inscription-detail-btn-cancel" onClick={onCloseInscriptionDetails}>Cancel</button>
                        </div>
                        
                    </form>
                </div>
            </div>
        </div>
        ,
        document.getElementById("modal")
    );
}

export {InscriptionDetailsModal};