import React from "react";
import "./styles/App.css";
import "./styles/root.css";

import { createHashRouter, RouterProvider } from "react-router-dom";
import { ToogleProvider } from "./hooks/ToogleContext";
import LoginPage from "./pages/LoginPage";
import { Dashboard } from "./pages/Admin/Dashboard";
import { StudentsInscriptions } from "./pages/Admin/StudentsInscriptions";
import { StudentsProgress } from "./pages/Admin/StudentsProgress";
import { Financial } from "./pages/Admin/Financial";
import { ProductsList } from "./pages/Admin/ProductsList";
import { Settings } from "./pages/Admin/Settings";
import { Users } from "./pages/Admin/Users";
import { Vouchers } from "./pages/Admin/Vouchers";
import { LoginAs } from "./pages/LoginAs";
import { StudentsHome } from "./pages/Learning/StudentsHome";
import { TrainerHome } from "./pages/Trainers/TrainerHome";
import { MyTrainerCourses } from "./pages/Trainers/MyTrainerCourses";
import { CourseInfo } from "./pages/Trainers/CourseInfo";
import { CourseStudents } from "./pages/Trainers/CourseStudents";
import { MyStudentCourses } from "./pages/Learning/MyStudentCourses";
import { MyStudentCourseInfo } from "./pages/Learning/MyStudentCourseInfo";
import { LearningRoutes } from "./pages/Admin/LearningRoutes";
import { CourseApp } from "./pages/Learning/CourseApp";

const router = createHashRouter ([
  {
    path: "/",
    element: <LoginPage/>,
  },
  {
    path: "/login-as",
    element: <LoginAs/>,
  },
  {
    path: "/students",
    element: <StudentsHome/>,
  },
  {
    path: "/students/my-courses",
    element: <MyStudentCourses/>,
  },
  {
    path: "/students/my-courses/:learningCourseId",
    element: <MyStudentCourseInfo/>,
  },
  {
    path: "/trainer",
    element: <TrainerHome/>,
  },
  {
    path: "/trainer/my-courses",
    element: <MyTrainerCourses/>,
  },
  {
    path: "/my-courses/course-info/:learningCourseId",
    element: <CourseInfo/>,
  },
  {
    path: "/students/course-app/:learningCourseId",
    element: <CourseApp/>,
  },
  {
    path: "/my-courses/course-students/:learningCourseId",
    element: <CourseStudents/>,
  },
  {
    path: "/dashboard",
    element: <Dashboard/>,
  },
  {
    path: "/students-inscriptions",
    element: <StudentsInscriptions/>,
  },
  {
    path:"/students-progress",
    element: <StudentsProgress/>
  },
  {
    path:"/financial",
    element:<Financial/>
  },
  {
    path: "/products-list",
    element: <ProductsList/>,
  },
  {
    path: "/settings",
    element: <Settings/>,
  },
  {
    path: "/users",
    element: <Users/>,
  },
  {
    path: "/vouchers",
    element: <Vouchers/>,
  },
  {
    path: "/learning-routes",
    element: <LearningRoutes/>,
  },

]);

function App() {

  return (
    <ToogleProvider>
      <RouterProvider router = {router}/>
    </ToogleProvider>
   
  );
}

export default App;
