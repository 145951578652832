import React from "react";
import "./../../styles/myCourseFrame.css"

import { useParams } from "react-router-dom";

import { TrainerNavbar } from "../../components/Trainers/TrainerNavbar";
import { TrainerLeftBar } from "../../components/Trainers/TrainerLeftBar";
import { CourseStudentsView } from "../../components/Trainers/CourseStudentsView";

function CourseStudents () {

    let learningCourseId = useParams();

    return(
        <div className="my-course-frame-main-container">
            <div className="my-course-frame-left-bar">
                <TrainerNavbar/>
            </div>
            
            <div className="my-course-frame-content-container">
                <div className="my-course-frame-left-bar">
                    <TrainerLeftBar
                        learningCourseId = {learningCourseId}
                    />
                </div>
                <div className="my-course-frame-info-grid">
                    <CourseStudentsView
                        learningCourseId = {learningCourseId}
                    /> 
                </div>
            </div>
        </div>
    );
}

export { CourseStudents };