import React from "react";

import "./../styles/spinner.css";

function Spinner () {
    return (
        <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    );
}

export { Spinner }