import React, {useState} from "react";
import "./../../styles/dashboardPage.css";

import logo from "./../../imgs/Brand/logo-main.webp";
import LoginPage from "../LoginPage";

import { useNavigate } from "react-router-dom";
import useToken from "../../hooks/useToken";

import { LeftMenu } from "../../components/AdminPanel/LeftMenu";
import { LearningRoutesView } from "../../components/AdminPanel/LearningRoutesView";
import { AddButtom } from "../../components/AdminPanel/AddButtom";
import { LogoutButtom } from "../../components/AdminPanel/LogoutButtom";
import { AddNewLearningRouteForm } from "../../components/AdminPanel/AddNewLearningRouteForm";

function LearningRoutes() {

  const [shouldShowPLearningRouteDetailsModal, setshouldShowPLearningRouteDetailsModal]= React.useState(false);
  const navigate = useNavigate();

  const toogleAddButtomClick = () => {
    setshouldShowPLearningRouteDetailsModal(!shouldShowPLearningRouteDetailsModal);
  };

  const {token, setToken} = useToken();
  
  if (!token){
    return <LoginPage
    //  setToken={setToken}
    />
  }

  return (
    <React.Fragment>
     
      <div className="dashboardPage-main-container">
        
        <div className="dashboardPage-left-panel">
          <div className="dashboardPage-left-panel-header">
            <img src={logo} className="dashboardPage-logo" title="agile excellence main logo" alt="agilexcellence logo" onClick ={() => navigate("/")}/>
            <LeftMenu/>
          </div>
          <LogoutButtom/>
        </div>

        <div className="dashboardPage-right-panel">
          <AddButtom
            btn_name="Add new route"
            onClick={toogleAddButtomClick}
          />
          <LearningRoutesView/>
          {shouldShowPLearningRouteDetailsModal && <AddNewLearningRouteForm onCloseAddNewLearningRouteFormClick={toogleAddButtomClick}/>}
        </div>

      </div>
    </React.Fragment>
  );
}

export {LearningRoutes};