import React, {useEffect,useCallback} from "react";
import  {createPortal}  from "react-dom";
import "./../../styles/courseDetailsModal.css";

import closeIcon from "./../../imgs/ActionsIcons/close-circle.svg";

import { ToogleContext } from "../../hooks/ToogleContext";
import { getGraphQl } from "../../hooks/ApiCalls";
import { isTheSameObject, getNewObject,buildMutationBody } from "../../utils/utils";

function CourseDetailsModal ({learningCourseId, onCloseInscriptionDetails}) {

    const [courseDetailModal, setcourseDetailModal] = React.useState("");
    const [courseDetailInitialData,setcourseDetailInitialData] = React.useState("");
    const [isDetailsUpdated, setisDetailsUpdated] = React.useState(false);

    function handleInscriptionDetailChange(evt) {
        const { target } = evt;
        const { name, value } = target;
    
        const newValues = {
          ...courseDetailModal,
          [name]: value,
        };

        setcourseDetailModal(newValues);
        
        if (!isTheSameObject(newValues,courseDetailInitialData)){
            setisDetailsUpdated(true);
        } else {
            setisDetailsUpdated(false);
        }

    }

    const handleSaveChanges = async (evt) => {
        evt.preventDefault();

        let auxObj = courseDetailModal;
        let SaveChangesQuery = `
            mutation {
                updateLearningCourses(LearningCourses:${buildMutationBody(auxObj)}
                ) {
                    id
                    }
            }
        `;

        let status = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: SaveChangesQuery});
        if (status){window.alert("Changes Saved")}
        window.location.reload();
    };

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            onCloseInscriptionDetails();
        }
      }, []);
    
      useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
      }, [escFunction]);

    let graphQuery = `
        query {
            GetLearningCoursesbylearningCourseId (LearningCourses:{learningCourseId:"${learningCourseId}"}) {
            courseIdentifier
            name
            productId
            learningCourseId
            startDate
            endDate
            enabled
            inscriptionEnabled
            courseWhatsapp
            coursePPT
            courseAgenda
            courseYoutubeList
            }
        }
    `;

    useEffect(() => {
        (async () => {
            const {data:{data:{GetLearningCoursesbylearningCourseId}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            
            setcourseDetailModal(getNewObject(GetLearningCoursesbylearningCourseId));
            setcourseDetailInitialData(getNewObject(GetLearningCoursesbylearningCourseId));
        })();

    }, []);

    return createPortal(
        <div className="ModalBackground">
            <div className="inscription-details-modal-main-container">
                <div className="inscription-details-modal-container">
                    <img src={closeIcon} className="inscription-detail-modal-close-btn" alt="close modal icon" onClick={onCloseInscriptionDetails}/>   
                    <form className="inscription-detail-modal-form">
                        
                        <div className="inscription-detail-form-student-info">
                            <h2 className="inscription-detail-form-title"> Course Information</h2>
                            <div className="inscription-detail-student-info-block">
                                <div className="inscription-detail-student-info-line">
                                    <div className="inscription-detail-modal-item">
                                        <label for="name" className="inscription-detail-modal-item-label">Name</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {courseDetailModal.name} name="name" />
                                    </div>
                                    <div className="inscription-detail-modal-item">
                                        <label for="courseIdentifier" className="inscription-detail-modal-item-label">courseIdentifier</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {courseDetailModal.courseIdentifier} name="courseIdentifier"/>
                                    </div>
                                    <div className="inscription-detail-modal-item">
                                        <label for="productId" className="inscription-detail-modal-item-label">productId</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {courseDetailModal.productId} name="productId"/>
                                    </div>
                                </div>
                                <div className="inscription-detail-student-info-line">
                                    <div className="inscription-detail-modal-item">
                                        <label for="learningCourseId" className="inscription-detail-modal-item-label">learningCourseId</label>
                                        <input className="inscription-detail-modal-item-value"  onChange={handleInscriptionDetailChange} type="text" value = {courseDetailModal.learningCourseId} name="learningCourseId"/>
                                    </div>
                                    <div className="inscription-detail-modal-item">
                                        <label for="startDate" className="inscription-detail-modal-item-label">startDate</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {courseDetailModal.startDate} name="startDate"/>
                                    </div>
                                    <div className="inscription-detail-modal-item">
                                        <label for="endDate" className="inscription-detail-modal-item-label">endDate</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {courseDetailModal.endDate} name="endDate"/>
                                    </div>
                                </div>
                                <div className="inscription-detail-student-info-line">
                                    <div className="inscription-detail-modal-item">
                                        <label for="courseAgenda" className="inscription-detail-modal-item-label">courseAgenda</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {courseDetailModal.courseAgenda} name="courseAgenda"/>
                                    </div>
                                    <div className="inscription-detail-modal-item">
                                        <label for="coursePPT" className="inscription-detail-modal-item-label">coursePPT</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {courseDetailModal.coursePPT} name="coursePPT"/>
                                    </div>
                                    <div className="inscription-detail-modal-item">
                                        <label for="courseWhatsapp" className="inscription-detail-modal-item-label">Whatsapp</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {courseDetailModal.courseWhatsapp} name="courseWhatsapp" />
                                    </div>
                                    <div className="inscription-detail-modal-item">
                                        <label for="courseYoutubeList" className="inscription-detail-modal-item-label">YoutubeList</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleInscriptionDetailChange} type="text" value = {courseDetailModal.courseYoutubeList} name="courseYoutubeList" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="inscription-detail-form-btns">
                        {isDetailsUpdated ? <button className="inscription-detail-btn-save" onClick={handleSaveChanges}>Save Changes</button> : ""}
                            <button className="inscription-detail-btn-cancel" onClick={onCloseInscriptionDetails}>Cancel</button>
                        </div>
                        
                    </form>
                </div>
            </div>
        </div>
        ,
        document.getElementById("modal")
    );
}

export {CourseDetailsModal};