import React from "react";
import { LoginAsView } from "../components/LoginAs/LoginAsView";

function LoginAs () {

    return(
        <LoginAsView/>  
    );
}

export { LoginAs };