import axios from "axios";

async function getGraphQl(args) {
    return axios({
        url: args.url,
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            query: args.query
        } 
        })
}

export {getGraphQl};