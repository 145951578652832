import React,{useEffect,useCallback} from "react";
import  {createPortal}  from "react-dom";
import "../../styles/AddVoucherForm.css";

import {getGraphQl} from "../../hooks/ApiCalls";
import { SelectPendingToVoucher } from "../Selector/SelectPendingToVoucher";

function AssignVoucherForm ({voucherGuid, onCloseAssingVoucherFormClick}) {

  const [values, setValues] = React.useState({
      voucherGuid: voucherGuid,
    });

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      onCloseAssingVoucherFormClick();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
  }, [escFunction]);

  let graphQuery= `
      mutation {
        assignVoucher(Vouchers:{voucherGuid:"${voucherGuid}", inscriptionGuid:"${values.inscriptionGuid}"}) {
          message
          status
        }
      }
    `;

  function handleChange(evt) {
    const { target } = evt;
    const { name, value } = target;

    const newValues = {
      ...values,
      [name]: value,
    };
    setValues(newValues);
  }
      
  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const {data:{data:{assignVoucher:{status}}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery})
    if (status == 200) {
      alert("Voucher assigned successfuly");
    } else {
      alert("Voucher was not assigned")
    }
    window.location.reload();
  }

  return createPortal(
        <div className="ModalBackground">
            <div className="add-voucher-form-main-container" onSubmit={handleSubmit}>
                <div className="add-voucher-form-container">
                    <h1 className="add-voucher-form-title">Assign voucher</h1>
                    <form className="add-voucher-form-inner-container">

                        <div className="add-voucher-form-items">
                            <label for="inscriptionGuid" className="add-voucher-form-item">Course Name</label>
                            <SelectPendingToVoucher
                              onChange={handleChange}
                              name="inscriptionGuid"
                            />
                        </div>
                        
                        <div className="add-voucher-btns">
                            <button className="add-voucher-btn-create" type="submit"> Assign Voucher </button>
                            <button className="add-voucher-btn-cancel" onClick={onCloseAssingVoucherFormClick}> Cancel </button>
                        </div>
                    </form>
                </div>
            </div>
          </div>
          ,
          document.getElementById("modal")
  );
}

export {AssignVoucherForm};