import React, {useState,useEffect} from "react";
import "./../../styles/courseContentAcordion.css";

import bookIcon from "../../imgs/StudentsIcons/book.svg";
import youtubeIcon from "../../imgs/StudentsIcons/youtubeIcon.svg";
import uncheckIcon from "../../imgs/radio_button_unchecked_FILL0_wght400_GRAD0_opsz48.svg";
import checkedIcon from "../../imgs/StudentsIcons/check-circle.svg";

function CourseContentAcordionItem ({name,isCompleted, moduleItemOrder, contentURL, contentType}) {

    return (
        
        <div className="course-content-acordion-module-content-item">
            <div className="course-content-acordion-module-content-item-left-container">
                <img src={contentType == "video" ? youtubeIcon: contentType == "reading" ? bookIcon : ""} className="course-content-acordion-module-content-reading-icon" />
                <h2 className="course-content-acordion-module-content-text">{name}</h2>
            </div>
            <img src={isCompleted == true ? checkedIcon: uncheckIcon} className="course-content-acordion-module-content-progress-icon"/>
        </div>
    )

}

export {CourseContentAcordionItem};