import React, {useEffect,useState} from "react";
import "../../styles/productCard.css";

import { ToogleButtom } from "./ToogleButtom";
import { LearningRouteDetailsModal } from "./LearningRouteDetailsModal";

function LearningRouteCard ({learningRouteIcon, LearningRouteName, LearningRouteDescription,learningRouteId }) {

  const [openLearningRouteDetails, setopenLearningRouteDetails] = useState(false);

  const handleViewDetails = () => {
    setopenLearningRouteDetails(true);
  };

  const handleCloseLearningRouteDetails = () => {
    setopenLearningRouteDetails(false);
  };

    return (
      <React.Fragment>
        <div className="product-card-panel-main-container">
            <div className="product-card-header">
              <div className="product-card-header-info">
                <img src={learningRouteIcon} className="product-card-header-icon"  title="imagen marketing de la ruta de aprendizaje" alt="icono de la ruta de aprenziaje"/>
                <h4 className="product-card-header-info-name"> {LearningRouteName}</h4>
              </div>
            </div>
            <div className="product-card-description"><p> {LearningRouteDescription}</p></div>
            <div className="product-card-view-detail-btn" onClick={handleViewDetails}><h3>View details</h3></div>
        </div>

        {openLearningRouteDetails &&  <LearningRouteDetailsModal learningRouteId= {learningRouteId} onCloseLearningRouteDetails={handleCloseLearningRouteDetails} />}
      </React.Fragment>
    );
}

export { LearningRouteCard };