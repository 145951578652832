import React, { useState, useEffect } from "react";
import "./../../styles/userCoursesGrid.css";

import { getGraphQl } from "../../hooks/ApiCalls";
import { CourseInscription } from "./CourseInscription";

function UserCoursesGrid ({dni}) {
   
    const [paymentList, setpaymentList] = useState([]);
    
    let graphQuery = `
        query {
            GetUserInscriptions(StudentInscription:{dni:"${dni}"}) {
            id
            courseInscriptionDate
            learningCourseId
            inscriptionGuid
            InscriptionConfirmed
            paymentReceived
            InscriptionEnabled
            courseName
            }
        }
    `;

    useEffect(() => {

        (async () => {
            const {data:{data:{GetUserInscriptions}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            setpaymentList(GetUserInscriptions);
        })();

      }, []);

    return(
        <div className="user-courses-grid-main-container">
            <table className="user-courses-grid-table">
                <tr>
                    <td className="user-courses-grid-table-colum">Inscription Date</td>
                    <td className="user-courses-grid-table-colum">Course Name</td>
                    <td className="user-courses-grid-table-colum">Confirmed</td>
                    <td className="user-courses-grid-table-colum">Payed</td>
                    <td className="user-courses-grid-table-colum">Enabled</td>
                    <td className="user-courses-grid-table-colum">Actions</td>
                </tr>
                {   
                    paymentList?.map( (payment) => ( 
                        <CourseInscription 
                            key={payment.id} 
                            courseInscriptionDate = {payment?.courseInscriptionDate}
                            courseName = {payment?.courseName}
                            InscriptionConfirmed = {payment?.InscriptionConfirmed}
                            paymentReceived = {payment?.paymentReceived}
                            InscriptionEnabled = {payment?.InscriptionEnabled}
                            inscriptionGuid= {payment?.inscriptionGuid}
                        /> 
                    ))
                }
                
            </table>
        </div>
    );

}

export {UserCoursesGrid};