import React, {useEffect, useState} from "react";
import "../../styles/vouchersView.css"

import { ToogleContext } from "../../hooks/ToogleContext";

import { getGraphQl } from "../../hooks/ApiCalls";
import { Vouchers } from "./Vouchers";


function VouchersView () {

    const {voucherList, setvoucherList} = React.useContext(ToogleContext);
    
    let graphQuery = `
        query{
            Vouchers {
            id
            voucherGuid
            productId
            productName
            isAssigned
            inscriptionGuid
            purchaseDate
            enabled
            }
        }
    `;

    useEffect(() => {

        (async () => {
            const {data:{data:{Vouchers}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            setvoucherList(Vouchers);
        })();

      }, []);

    return (
        <div className="vouchers-view-main-container">
            <table className="vouchers-table">
                <tr>
                    <td className="vouchers-table-colum">Purchase Date</td>
                    <td className="vouchers-table-colum">Product Name</td>
                    <td className="vouchers-table-colum">Assigned</td>
                    <td className="vouchers-table-colum">Enabled</td>
                    <td className="vouchers-table-colum">Actions</td>
                </tr>
                {   
                    voucherList.map( (voucher) => ( 
                        <Vouchers 
                            key={voucher.id} 
                            productName = {voucher.productName}
                            isAssigned = {voucher.isAssigned}
                            voucherGuid= {voucher.voucherGuid}
                            purchaseDate = {voucher.purchaseDate}
                            enabled={voucher.enabled}
                        /> 
                    ))
                }
                
            </table>
        </div>
    );

}

export { VouchersView };