import React from "react";
import "./../../styles/loginAsCard.css";

import { useNavigate } from "react-router-dom";

function LoginAsCard ({roleName, roleIcon, navigateRoute}) {

    const navigate = useNavigate();

    return(
            <div className="login-as-card-main-container" onClick ={() => navigate(`${navigateRoute}`)}>
                <img src={roleIcon} className="login-as-card-logo" alt="icono ilustrativo del rol"/>
                <h1 className="login-as-card-title">{roleName}</h1>
            </div>
    );
}

export {LoginAsCard};