import React, {useEffect, useState} from "react";
import "../../styles/courseStudentsView.css"

import courseIcon from "../../imgs/github.svg";
import { ToogleContext } from "../../hooks/ToogleContext";
import { CourseStudents } from "./CourseStudents";

import { getGraphQl } from "../../hooks/ApiCalls";

function CourseStudentsView ({learningCourseId}) {

    const {inscriptionsList, setinscriptionsList} = React.useContext(ToogleContext);
    
    let graphQuery = `
        query{
            GetLearningCourseStudents(LearningCourseStudents: {learningCourseId: "${learningCourseId.learningCourseId}"}) {
            id
            name
            lastname
            email
            inscriptionGuid
            progress
            courseStep
            }
        }
    `;

    useEffect(() => {

        (async () => {
            const {data:{data:{GetLearningCourseStudents}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            console.log(GetLearningCourseStudents)
            setinscriptionsList(GetLearningCourseStudents);
        })();

      }, []);

    return (
        <div className="course-students-view-main-container">
            <table className="course-students-table">
                <tr>
                    <td className="course-students-table-colum">Name</td>
                    <td className="course-students-table-colum">Lastname</td>
                    <td className="course-students-table-colum">Email</td>
                    <td className="course-students-table-colum">Progress</td>
                    <td className="course-students-table-colum">Course Step</td>
                    <td className="course-students-table-colum">Actions</td>
                </tr>
                {   
                    inscriptionsList.map( (inscription) => ( 
                        
                        <CourseStudents 
                            key={inscription.id} 
                            name = {inscription.name}
                            lastname = {inscription.lastname}
                            email = {inscription.email}
                            progress={inscription.progress}
                            courseStep= {inscription.courseStep} 
                            inscriptionGuid= {inscription.inscriptionGuid}
                        /> 
                    ))
                }
                
            </table>
        </div>
    );

}

export {CourseStudentsView};