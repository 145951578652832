import React, {useEffect,useState} from "react";
import "../../styles/productCard.css";

import { ToogleButtom } from "./ToogleButtom";
import { ProductDetailsModal } from "./ProductDetailsModal";

function ProductCard ({productIcon, productName, productDescription, productId}) {

  const [openProductDetails, setopenProductDetails] = useState(false);

  const handleViewDetails = () => {
    setopenProductDetails(true);
  };

  const handleCloseProductDetails = () => {
    setopenProductDetails(false);
  };

    return (
      <React.Fragment>
        <div className="product-card-panel-main-container">
            <div className="product-card-header">
              <div className="product-card-header-info">
                <img src={productIcon} className="product-card-header-icon" alt="icono del producto"/>
                <h4 className="product-card-header-info-name"> {productName}</h4>
              </div>
            </div>
            <div className="product-card-description"><p> {productDescription}</p></div>
            <div className="product-card-view-detail-btn" onClick={handleViewDetails}><h3>View details</h3></div>
        </div>

        {openProductDetails &&  <ProductDetailsModal productId= {productId} onCloseProductDetails={handleCloseProductDetails} />}
      </React.Fragment>
    );
}

export { ProductCard };