import React, { useState, useEffect } from "react";
import "./../../styles/paymentGrid.css";

import { getGraphQl } from "./../../hooks/ApiCalls";
import { PaymentEntry } from "./PaymentEntry";

function PaymentsGrid ({inscriptionGuid}) {
   
    const [paymentList, setpaymentList] = useState([]);
    
    let graphQuery = `
        query {
            GetInscriptionPayments(Payment:{inscriptionGuid:"${inscriptionGuid}"}) {
            id
            name
            paymentGuid
            productId
            learningCourseId
            inscriptionGuid
            paymentDate
            paymentAmount
            balance
            isPaymentCompleted
            }
        }
    `;

    useEffect(() => {

        (async () => {
            const {data:{data:{GetInscriptionPayments}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            setpaymentList(GetInscriptionPayments);
        })();

      }, []);

    return(
        <div className="payment-grid-main-container">
            <table className="payment-grid-table">
                <tr>
                    <td className="payment-grid-table-colum">Date</td>
                    <td className="payment-grid-table-colum">Description</td>
                    <td className="payment-grid-table-colum">Amount</td>
                    <td className="payment-grid-table-colum">Balance</td>
                    <td className="payment-grid-table-colum">Actions</td>
                </tr>
                {   
                    paymentList.map( (payment) => ( 
                        <PaymentEntry 
                            key={payment.id} 
                            paymentDate = {payment.paymentDate}
                            name = {payment.name}
                            paymentAmount = {payment.paymentAmount}
                            balance = {payment.balance}
                            paymentGuid= {payment.paymentGuid}
                        /> 
                    ))
                }
                
            </table>
        </div>
    );

}

export {PaymentsGrid};