import React from "react";

import { TrainerNavbar } from "../../components/Trainers/TrainerNavbar";
import { MyTrainerCourses } from "./MyTrainerCourses";


function TrainerHome () {

    return(
        <React.Fragment>
            <TrainerNavbar/>
        </React.Fragment>
    );
}

export { TrainerHome };