import React, {useState} from "react";
import "./../../styles/courseInscription.css";

import viewIcon from "./../../imgs/ActionsIcons/view.svg";
import cancelIcon from "./../../imgs/ActionsIcons/cancel.svg";
import upIcon from "./../../imgs/ActionsIcons/up-direction.svg";
import downIcon from "./../../imgs/ActionsIcons/down-direction.svg";

import { InscriptionDetailsModal } from "./InscriptionDetailsModal";
import {getGraphQl} from "../../hooks/ApiCalls";

function RouteItem ({stepGuid,name,description,difficulty, competency, onCloseInscriptionDetails}) {

    const [shouldOpenActionMenu, setshouldOpenActionMenu] = React.useState(false);
    const [openPaymentDetails, setopenPaymentDetails] = React.useState(false);

    const handleInscriptionActions = () => {
        setshouldOpenActionMenu(!shouldOpenActionMenu);
    };

    const handleCloseInscriptionDetails = () => {
        setopenPaymentDetails(false);
    };

    const handleViewDetails = () => {
        setshouldOpenActionMenu(false);
        setopenPaymentDetails(true);
    };

    return(
        <React.Fragment>
            <tr className="course-inscription-entry-table-line">
                <td className="course-inscription-entry-table-item">{name}</td>
                <td className="course-inscription-entry-table-item">{difficulty}</td>
                <td className="course-inscription-entry-table-item">{competency}</td>
                <td className="course-inscription-entry-table-actions-item" onClick={handleInscriptionActions}>Actions</td>
            </tr> 

            <div className= {`course-inscription-entry-action-menu ${shouldOpenActionMenu && "course-inscription-entry-action-menu-open" } `}>
                    <div className="course-inscription-entry-action-items-container">
                        <div className="course-inscription-entry-action-item-container">
                            <li className="course-inscription-entry-action-item" onClick={handleViewDetails}>View Details</li>
                            <img src={viewIcon} className="course-inscription-entry-action-icon"/>
                        </div>

                        <div className="course-inscription-entry-action-item-container">
                            <li className="course-inscription-entry-action-item">One Up</li>
                            <img src={upIcon} className="course-inscription-entry-action-icon"/>
                        </div>

                        <div className="course-inscription-entry-action-item-container">
                            <li className="course-inscription-entry-action-item">One Down</li>
                            <img src={downIcon} className="course-inscription-entry-action-icon"/>
                        </div>

                        <div className="course-inscription-entry-action-item-container">
                            <li className="course-inscription-entry-action-item">Delete Step</li>
                            <img src={cancelIcon} className="course-inscription-entry-action-icon"/>
                        </div>
                    </div>
            </div>

            {/* {openPaymentDetails &&  <InscriptionDetailsModal inscriptionGuid= {inscriptionGuid} onCloseInscriptionDetails={handleCloseInscriptionDetails} />} */}
        </React.Fragment>
        
    );
}

export {RouteItem};