import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import "./../../styles/trainerLeftBar.css";

import courselogo from "./../../imgs/CourseIcons/SMCaratula.png";
import { ToogleContext } from "../../hooks/ToogleContext";

import { getGraphQl } from "../../hooks/ApiCalls";

function TrainerLeftBar ({learningCourseId}) {

    const {openHamburguer, setopenHamburguer} = React.useContext(ToogleContext);
    const [courseName, setcourseName] = useState("");
    const navigate = useNavigate();

    let graphQuery = `
        query {
            GetLearningCoursesbylearningCourseId(LearningCourses:{learningCourseId:"${learningCourseId.learningCourseId}"}) {
            name
            }
        }
    `;

    useEffect(() => {
        (async () => {
            const {data:{data:{GetLearningCoursesbylearningCourseId}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            setcourseName(GetLearningCoursesbylearningCourseId.name);
        })();

      }, []);

    return (
        <div className={`trainer-left-bar-main-container ${openHamburguer && "opened"}`}>
            <div className="trainer-left-bar-content-container">
                <div className="trainer-left-bar-top-content">
                    
                    <div className="trainer-left-bar-course-info">
                        <img src={courselogo} className="trainer-left-bar-course-icon"/>
                        <h1 className="trainer-left-bar-course-name">{courseName}</h1>
                    </div>

                    <div className="trainer-left-bar-options-container">
                        <div className="trainer-left-bar-option">
                            <h1 className="trainer-left-bar-option-text" onClick={() => navigate(`/my-courses/course-info/${learningCourseId.learningCourseId}`)}>Course Info</h1>
                        </div>
                        <div className="trainer-left-bar-option">
                            <h1 className="trainer-left-bar-option-text" onClick={() => navigate(`/my-courses/course-students/${learningCourseId.learningCourseId}`)}>Course Students</h1>
                        </div>
                    </div>

                </div>
                
            </div>
        </div>
    );

}

export {TrainerLeftBar};