import React from "react";
import "./../../styles/logoutButtom.css";

import avatar from "../../imgs/jesus.jpeg";
import { useNavigate } from "react-router-dom";

function LogoutButtom () {

    const navigate = useNavigate();

    const handleLogOut = () => {
        localStorage.removeItem('agilexcellence.io:Token');
        window.location.reload(true);
    };

    
    return(
        <div className="dashboardPage-logout-btn" onClick={() => { navigate("/"); handleLogOut();}}>
            <img src= {avatar} className="logout-btn-avatar" alt="user avatar"/>
            <h4 className="logout-btn-name">Logout</h4>
        </div>
    );
}

export {LogoutButtom};