import React, {useEffect,useCallback} from "react";
import  {createPortal}  from "react-dom";
import "./../../styles/inscriptionDetailsModal.css";

import closeIcon from "./../../imgs/ActionsIcons/close-circle.svg";

import { ToogleContext } from "../../hooks/ToogleContext";
import { getGraphQl } from "../../hooks/ApiCalls";
import { isTheSameObject, getNewObject,buildMutationBody } from "../../utils/utils";

import { ProductStepFlowGrid } from "./ProductStepFlowGrid";

function ProductDetailsModal ({productId, onCloseProductDetails}) {

    const [productDetailModal, setproductDetailModal] = React.useState("");
    const [productDetailInitialData,setproductDetailInitialData] = React.useState("");
    const [isDetailsUpdated, setisDetailsUpdated] = React.useState(false);

    function handleProductDetailChange(evt) {
        const { target } = evt;
        const { name, value } = target;
    
        const newValues = {
          ...productDetailModal,
          [name]: value,
        };
        console.log("new values>>>>>", newValues)
        setproductDetailModal(newValues);
        
        if (!isTheSameObject(newValues,productDetailInitialData)){
            setisDetailsUpdated(true);
        } else {
            setisDetailsUpdated(false);
        }

    }

    const handleSaveChanges = async (evt) => {
        evt.preventDefault();

        let auxObj = productDetailModal;
        console.log(auxObj);

        let SaveChangesQuery = `
            mutation{
                updateProducts (Products:
                    ${buildMutationBody(auxObj)}
                ) {
                    message
                    }
            }
        `;

        console.log(SaveChangesQuery);
        let status = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: SaveChangesQuery});
        if (status){window.alert("Changes Saved")}
        window.location.reload();
    };

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            onCloseProductDetails();
        }
      }, []);
    
      useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
      }, [escFunction]);

    let graphQuery = `
        query {
            GetProductsbyProductId(Products:{productId:"${productId}"}) {
            name
            productId
            price
            description
            image
            enabled
            }
        }
    `;

    useEffect(() => {
        (async () => {
            const {data:{data:{GetProductsbyProductId}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            setproductDetailModal(getNewObject(GetProductsbyProductId));
            setproductDetailInitialData(getNewObject(GetProductsbyProductId));
        })();

    }, []);

    return createPortal(
        <div className="ModalBackground">
            <div className="inscription-details-modal-main-container">
                <div className="inscription-details-modal-container">
                    <img src={closeIcon} className="inscription-detail-modal-close-btn" alt="close modal icon" onClick={onCloseProductDetails}/>   
                    <form className="inscription-detail-modal-form">
                        
                        <div className="inscription-detail-form-student-info">
                            <h2 className="inscription-detail-form-title"> Product Information</h2>
                            <div className="inscription-detail-student-info-block">
                                <div className="inscription-detail-student-info-line">
                                    <div className="inscription-detail-modal-item">
                                        <label for="name" className="inscription-detail-modal-item-label">Name</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleProductDetailChange} type="text" value = {productDetailModal.name} name="name" />
                                    </div>
                                    <div className="inscription-detail-modal-item">
                                        <label for="productId" className="inscription-detail-modal-item-label">productId</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleProductDetailChange} type="text" value = {productDetailModal.productId} name="productId"/>
                                    </div>
                                    <div className="inscription-detail-modal-item">
                                        <label for="description" className="inscription-detail-modal-item-label">description</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleProductDetailChange} type="text" value = {productDetailModal.description} name="description"/>
                                    </div>
                                </div>
                                <div className="inscription-detail-student-info-line">
                                    <div className="inscription-detail-modal-item">
                                        <label for="price" className="inscription-detail-modal-item-label">price</label>
                                        <input className="inscription-detail-modal-item-value"  onChange={handleProductDetailChange} type="number" value = {productDetailModal.price} name="price"/>
                                    </div>
                                    <div className="inscription-detail-modal-item">
                                        <label for="image" className="inscription-detail-modal-item-label">image</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleProductDetailChange} type="text" value = {productDetailModal.image} name="image"/>
                                    </div>
                                    <div className="inscription-detail-modal-item">
                                        <label for="enabled" className="inscription-detail-modal-item-label">enabled</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleProductDetailChange} type="text" value = {productDetailModal.enabled} name="enabled"/>
                                    </div>
                                </div>
                                <div className="inscription-detail-student-info-line">
                                    <div className="inscription-detail-modal-item">
                                        <label for="city" className="inscription-detail-modal-item-label">City</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleProductDetailChange} type="text" value = {productDetailModal.city} name="city"/>
                                    </div>
                                    <div className="inscription-detail-modal-item">
                                        <label for="country" className="inscription-detail-modal-item-label">Country</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleProductDetailChange} type="text" value = {productDetailModal.country} name="country"/>
                                    </div>
                                    <div className="inscription-detail-modal-item">
                                        <label for="company" className="inscription-detail-modal-item-label">Company</label>
                                        <input className="inscription-detail-modal-item-value" onChange={handleProductDetailChange} type="text" value = {productDetailModal.company} name="company" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="inscription-detail-form-student-info">
                            <h2 className="inscription-detail-form-title"> Product Stepflow</h2>
                            <ProductStepFlowGrid
                                productId={productId}
                            />
                        </div>
                        
                        <div className="inscription-detail-form-btns">
                        {isDetailsUpdated ? <button className="inscription-detail-btn-save" onClick={handleSaveChanges}>Save Changes</button> : ""}
                            <button className="inscription-detail-btn-cancel" onClick={onCloseProductDetails}>Cancel</button>
                        </div>
                        
                    </form>
                </div>
            </div>
        </div>
        ,
        document.getElementById("modal")
    );
}

export {ProductDetailsModal};