import React from "react";
import "../../styles/addButtom.css";

import addIcon from "../../imgs/icons8-plus.svg";

function AddButtom ({btn_name,onClick}) {
    return (
        <div className="dashboardPage-add-btn" onClick={onClick}>
            <img src={addIcon} className="dashboardPage-add-btn-icon" alt="add icon"/>
            <h4>{btn_name}</h4>
          </div>
    );
}

export  {AddButtom};