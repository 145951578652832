import React from "react";
import "../../styles/leftMenu.css"

import { useNavigate } from "react-router-dom";
import { ToogleContext } from "../../hooks/ToogleContext";
import useAccessLevel from "../../hooks/useAccessLevel";

function LeftMenu () {

    const navigate = useNavigate();
    const {userLocation, setuserLocation} = React.useContext(ToogleContext);
    const {access_level, setaccess_level} = useAccessLevel();
    
    return(
        
        <ul className="dashboardPage-left-menu-items">
            {
                access_level== "356464fa-ea81-4921-a4f7-c4d6f1764855" ?
                <li className={`dashboardPage-left-menu-item ${userLocation[0] && "active"}`}><h4 onClick ={() => navigate("/dashboard")} className="dashboardPage-left-menu-item-text">Open Courses</h4></li>
                :
                <div></div>
            }
            {
                access_level== "356464fa-ea81-4921-a4f7-c4d6f1764855" || access_level == "e62b645e-feca-49c4-b7ee-70b28dee2d9a" ?
                <li className={`dashboardPage-left-menu-item ${userLocation[1] && "active"}`}><h4 onClick ={() => navigate("/students-inscriptions")} className="dashboardPage-left-menu-item-text">Open Inscriptions</h4></li>
                :
                <div></div>
            }
            {
                access_level== "356464fa-ea81-4921-a4f7-c4d6f1764855" || access_level == "e62b645e-feca-49c4-b7ee-70b28dee2d9a" ?
                <li className={`dashboardPage-left-menu-item ${userLocation[2] && "active"}`}><h4 onClick ={() => navigate("/students-progress")} className="dashboardPage-left-menu-item-text">Students Progress</h4></li>
                :
                <div></div>
            }
            {
                access_level== "356464fa-ea81-4921-a4f7-c4d6f1764855" || access_level == "e62b645e-feca-49c4-b7ee-70b28dee2d9a" ?
                <li className={`dashboardPage-left-menu-item ${userLocation[3] && "active"}`}><h4 onClick ={() => navigate("/financial")} className="dashboardPage-left-menu-item-text">Financial</h4></li>
                :
                <div></div>
            }
            {
                access_level== "356464fa-ea81-4921-a4f7-c4d6f1764855" ?
                <li className={`dashboardPage-left-menu-item ${userLocation[4] && "active"}`}><h4 onClick ={() => navigate("/products-list")} className="dashboardPage-left-menu-item-text">Products</h4></li>
                :
                <div></div>
            }
            {
                access_level== "356464fa-ea81-4921-a4f7-c4d6f1764855" || access_level == "e62b645e-feca-49c4-b7ee-70b28dee2d9a" ?
                <li className={`dashboardPage-left-menu-item ${userLocation[5] && "active"}`}><h4 onClick ={() => navigate("/users")} className="dashboardPage-left-menu-item-text">Users</h4></li>
                :
                <div></div>
            }
            {
                access_level== "356464fa-ea81-4921-a4f7-c4d6f1764855" ?
                <li className={`dashboardPage-left-menu-item ${userLocation[6] && "active"}`}><h4 onClick ={() => navigate("/vouchers")} className="dashboardPage-left-menu-item-text">Vouchers</h4></li>
                :
                <div></div>
            }
            {
                access_level== "356464fa-ea81-4921-a4f7-c4d6f1764855" ?
                <li className={`dashboardPage-left-menu-item ${userLocation[7] && "active"}`}><h4 onClick ={() => navigate("/learning-routes")} className="dashboardPage-left-menu-item-text">Learning Routes</h4></li>
                :
                <div></div>
            }
            {
                access_level== "356464fa-ea81-4921-a4f7-c4d6f1764855"  ?
                <li className={`dashboardPage-left-menu-item ${userLocation[8] && "active"}`}><h4 onClick ={() => navigate("/settings")} className="dashboardPage-left-menu-item-text">Settings</h4></li>
                :
                <div></div>
            }
            
          </ul>
    );
}

export {LeftMenu};